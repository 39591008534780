import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import EditProfile from './EditProfile';
import Home from './Home';
import { isCompletedUser } from './types';
import LoadingPage from './ui/LoadingPage';
import SignUpModal from './ui/SignUpModal/SignUpModal';
import { handleFbAuth, registerSegmentPage, useFirebaseAuthUser } from './utils';
import ViewLinks from './ViewLinks';

export default function Navigation() {
    const notistack = useSnackbar();
    const reactHistory = useHistory();

    const [awaitingSignInCallback, setAwaitingSignInCallback] = useState(false);
    const [authUser, userDataIsLoading, showSignUpFlow] = useFirebaseAuthUser();

    const identifyAnalytics = useCallback(() => {
        if (authUser && isCompletedUser(authUser)) {
            // @ts-ignore
            window.analytics?.identify(
                authUser?.uid,
                authUser,
                {},
                () => {
                    if (awaitingSignInCallback) {
                        reactHistory.push('/profile#links');
                        setAwaitingSignInCallback(false);
                    }
                }
            );
        }
    }, [authUser, awaitingSignInCallback, reactHistory]);

    useEffect(() => {
        return reactHistory.listen(() => {
            // @ts-ignore
            registerSegmentPage();
            identifyAnalytics();
        });
    }, [reactHistory, registerSegmentPage, identifyAnalytics]);

    useEffect(() => {
        registerSegmentPage();
    }, []);

    useEffect(() => {
        identifyAnalytics();
    }, [identifyAnalytics]);

    const signIn = useCallback(() => {
        handleFbAuth(
            notistack,
            () => {
                setAwaitingSignInCallback(true);
            },
        );
    }, [notistack]);

    const signUpFlow = useMemo(
        () =>
            (
                <>
                    {
                        showSignUpFlow && authUser && (
                            <SignUpModal
                                authUser={ authUser }
                            />
                        )
                    }
                </>
            ), [showSignUpFlow, authUser],
    );

    return (
        <>
            <Switch>
                <Route path='/profile'>
                    {
                        userDataIsLoading
                        ? <LoadingPage />
                        : <EditProfile authUser={ authUser } />
                    }

                    { signUpFlow }
                </Route>
                <Route path='/:user/:username'>
                    <ViewLinks />
                </Route>
                <Route path='/:username'>
                    <ViewLinks />
                </Route>
                <Route path='/'>
                    {
                        userDataIsLoading
                        ? <LoadingPage />
                        : <Home
                            authUser={ authUser }
                            signIn={ signIn }
                        />
                    }

                    { signUpFlow }
                </Route>
            </Switch>
        </>
    );
}
