import { Dialog, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { ReactElement } from 'react';
import { Link } from '../../types';

interface Props {
    link: Link;
    deleteLink: () => void;
    closeModal: () => void;
}

export default function ConfirmDeleteModal(
    {
        link,
        deleteLink,
        closeModal,
    }: Props,
): ReactElement {
    return (
        <Dialog open={ true }>
            <DialogTitle>
                Confirm link deletion
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You're about to delete the link&nbsp;
                    {
                        link.title ? (
                            <strong>
                                '
                                {
                                    link.title
                                }
                                '
                            </strong>
                        ) : (
                            <em>
                                untitled
                            </em>
                        )
                    }
                    . This will also delete any analytics stored about this link on Fitlink. Do you wish to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={ closeModal }
                    color='primary'
                >
                    Cancel
                </Button>
                <Button
                    onClick={ deleteLink }
                    color='primary'
                    autoFocus
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
