import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { AddAPhoto } from '@material-ui/icons';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { isCompletedUser, User, UserType } from '../types';
import { v4 } from 'uuid';

interface Props {
    companyName: string;
    setCompanyName: (newCompanyName: string) => void;
    companyNameError?: boolean;
    userIcon: string;
    setUserIcon: (newIcon: string) => void;
    userIconError?: boolean;
    username: string;
    setLoading?: (loading: boolean) => void;
    authUser: User;
    name: string;
    setCompanyNameError?: (newErrorState: boolean) => void;
    setUserIconError?: (newErrorState: boolean) => void;
    showUsernameTooltip?: boolean;
    large?: boolean;
    useNameAsCompany?: boolean;
    userType?: UserType;
}

export default function UserEditCard(
    {
        companyName,
        setCompanyName,
        companyNameError,
        userIcon,
        setUserIcon,
        userIconError,
        username,
        setLoading,
        authUser,
        name,
        userType,
        setCompanyNameError,
        setUserIconError,
        showUsernameTooltip,
        large,
        useNameAsCompany,
    }: Props,
): ReactElement {
    const updateCompanyName = useCallback((event) => {
        setCompanyName(event.target.value);
        setCompanyNameError && setCompanyNameError(false);
    }, [setCompanyName]);

    const [uploading, setUploading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleFileUpload = useCallback((event) => {
        if (!event.target.files || !event.target.files[0]) return;

        setLoading && setLoading(true);
        setUploading(true);
        enqueueSnackbar('Uploading file...', {
            variant: 'info',
            key: 'uploading',
            preventDuplicate: true,
            persist: true
        });

        window.onbeforeunload = () => 'Your avatar is still uploading - are you sure you want to leave?';

        const storageRef = firebase.storage().ref().child(v4());
        storageRef.put(event.target.files[0]).then(() => {
                storageRef
                    .getDownloadURL()
                    .then(url => {
                        setUserIcon(url);
                        setUploading(false);

                        closeSnackbar('uploading');
                        enqueueSnackbar('Uploaded new avatar successfully!', {
                            variant: 'success',
                            preventDuplicate: true,
                        });

                        window.onbeforeunload = null;
                        setLoading && setLoading(false);
                    })
                    .catch(() => {
                        enqueueSnackbar('Something went wrong while saving your image. Please try again later.', {
                            variant: 'error',
                        });
                        closeSnackbar('uploading');
                        window.onbeforeunload = null;
                        setLoading && setLoading(false);
                    });
            })
            .catch(() => {
                enqueueSnackbar(
                    'Something went wrong while uploading your image. Please try again or with a different image.',
                    {
                        variant: 'error',
                    },
                );
                closeSnackbar('uploading');
                window.onbeforeunload = null;
                setLoading && setLoading(false);
            });

        setUserIconError && setUserIconError(false);
    }, [setLoading, setUserIcon]);

    return (
        <div className={ `user-card${ large ? ' large' : '' }` }>
            <label
                htmlFor='file-picker'
                className='file-input'
            >
                <input
                    type='file'
                    className='avatar-picker'
                    id='file-picker'
                    accept='image/*'
                    onChange={ handleFileUpload }
                    disabled={uploading}
                />

                <div className={ `file-input-ui${ userIconError ? ' error' : '' }` }>
                    <Avatar
                        className='avatar'
                        src={ userIcon }
                    />
                    <AddAPhoto className='upload-icon' />
                </div>
            </label>

            <div className='name-container'>
                {
                    (isCompletedUser(authUser) ? authUser.userType === UserType.Company : userType) || useNameAsCompany
                    ? (
                        <>
                            <TextField
                                label={ useNameAsCompany ? 'Full name' : 'Company name' }
                                variant='outlined'
                                onChange={ updateCompanyName }
                                value={ companyName }
                                className='company-name'
                                error={ companyNameError }
                            />
                        </>
                    )
                    : (
                        <>
                            <p className='company-name'>
                                {
                                    name
                                }
                            </p>
                        </>
                    )
                }

                <p
                    className='username'
                    data-tip={ showUsernameTooltip && `Once you've chosen a username for your account, there's no way to change it.` }
                >
                    {
                        username
                    }
                </p>

                {
                    showUsernameTooltip && (
                        <ReactTooltip
                            effect='solid'
                            place='bottom'
                        />
                    )
                }
            </div>
        </div>
    );
}
