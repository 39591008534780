import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { ReactElement } from 'react';

interface Props {
    showGMBInfo: boolean;
    setShowGMBInfo: (newVal: boolean) => void;
}

export default function GMBDialog(
    {
        showGMBInfo,
        setShowGMBInfo,
    }: Props,
): ReactElement {
    return (
        <Dialog
            onClose={ () => setShowGMBInfo(false) }
            open={ showGMBInfo }
        >
            <DialogTitle>
                Why do we require a Google My Business link?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {/*TODO: Enter text here.*/ }
                    Test
                </DialogContentText>
                <DialogActions>
                    <Button
                        onClick={ () => setShowGMBInfo(false) }
                        color='primary'
                    >
                        OK
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
