export enum UserType {
    Person,
    Company,
}

export type DirtyFirebaseUpdateType = string | number | boolean | DirtyFirebaseUpdateType[] | null | undefined

export type FirebaseUpdateType = string | number | boolean | DirtyFirebaseUpdateType[] | {
    [key: string]: DirtyFirebaseUpdateType
}

export type DirtyFirebaseUpdateObj = {
    [key: string]: DirtyFirebaseUpdateType | DirtyFirebaseUpdateObj
}

export type FirebaseUpdateObj = {
    [key: string]: DirtyFirebaseUpdateType | DirtyFirebaseUpdateObj
}

export interface CommonUser {
    uid: string;
    name: string;
    email: string;
}

export interface IncompleteUser extends CommonUser {
    iconUrl: string | null;
    completedSignUp: false;
}

export interface Link {
    isPriority: boolean;
    index: number;
    title: string;
    description: string;
    id: string;
    url: string;
    disabled?: boolean;
    shortenedAs: string;
}

export interface LinkPageSettings {
    background: string;
    font: string;
    linkColor: string;
    featuredColor: string;
}

export interface CommonCompletedUser extends CommonUser {
    completedSignUp: true;
    iconUrl: string;
    username: string;
    links: Link[];
    bio: string;

    theme: LinkPageSettings;
}

export interface PersonalUser extends CommonCompletedUser {
    userType: UserType.Person;
}

export interface CompanyUser extends CommonCompletedUser {
    userType: UserType.Company;
    companyName: string;
    companyAddress?: string;
    companyPhone?: string;
    companyEmail?: string;
    companyWebsite?: string;

    companyPxId?: string;
    companyGaId?: string;

    companyFb?: string;
    companyIg?: string;
    companyYt?: string;
    companyTw?: string;
    companyTt?: string;
}

export interface BasicLinkDoc {
    clicks: number[];
    views: number[];
}

export interface CompleteLinkDoc extends BasicLinkDoc {
    gaId: string;
    pixelCode: string;
}

export interface UnfurledLinkDoc extends CompleteLinkDoc {
    imageUrl: string;
    title: string;
    description: string;
}

export type LinkDoc = CompleteLinkDoc | UnfurledLinkDoc;

export interface ContextualLink extends Link {
    parent: CompletedUser,
}

export function isUnfurled(link: LinkDoc): link is UnfurledLinkDoc {
    return (link as object).hasOwnProperty('imageUrl');
}

export type User<T extends UserType = any> = T extends UserType.Company
                                             ? CompanyUser
                                             : T extends UserType.Person
                                               ? PersonalUser
                                               : IncompleteUser;

export type CompletedUser = User<UserType.Person | UserType.Company>;

export function isCompletedUser(user: User): user is CompletedUser {
    return user.completedSignUp;
}

export function isCompanyUser(user: User): user is CompanyUser {
    return isCompletedUser(user) && user.hasOwnProperty('companyGaId');
}
