import TextField from '@material-ui/core/TextField';
import * as EmailValidator from 'email-validator';
import { AsYouType } from 'libphonenumber-js';
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max';
import places from 'places.js';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyUser, CompletedUser, PersonalUser, UserType } from '../types';
import UserEditCard from '../ui/UserEditCard';
import { filterUsername, updateUser, updateUserLinks, validateUrl } from '../utils';
import 'firebase/firestore';

interface Props {
    authUser: CompletedUser;
}

export default function Settings(
    {
        authUser,
    }: Props,
): ReactElement {
    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyName, setCompanyName] = useState(
        authUser.userType === UserType.Company ? authUser.companyName : undefined,
    );
    useEffect(() => {
        setCompanyNameError(!!(companyName && companyName.trim().length < 3));
    }, [companyName]);

    const prevCompanyAddress = (authUser.userType === UserType.Company && authUser.companyAddress) || '';

    const [companyAddress, setCompanyAddress] = useState(prevCompanyAddress);
    const [companyAddressError, setCompanyAddressError] = useState(false);
    const updateCompanyAddress = useCallback((event) => {
        setCompanyAddressError(event.target.value.trim().length <= 6);

        setCompanyAddress(event.target.value.trim() ? event.target.value : '');
    }, []);

    const [companyEmailError, setCompanyEmailError] = useState(false);
    const [companyEmail, setCompanyEmail] = useState(
        (authUser.userType === UserType.Company && authUser.companyEmail) || '',
    );
    const updateCompanyEmail = useCallback((event) => {
        setCompanyEmailError(
            !(EmailValidator.validate(event.target.value) || !event.target.value.trim().length),
        );

        setCompanyEmail(event.target.value.trim());
    }, []);

    const [companyWebsiteError, setCompanyWebsiteError] = useState(false);
    const [companyWebsite, setCompanyWebsite] = useState(
        (authUser.userType === UserType.Company && authUser.companyWebsite) || '',
    );
    const updateCompanyWebsite = useCallback((event) => {
        setCompanyWebsiteError(
            !(validateUrl(event.target.value) || !event.target.value.trim().length),
        );

        setCompanyWebsite(event.target.value.trim());
    }, []);

    const [companyPhoneError, setCompanyPhoneError] = useState(false);
    const [companyPhone, setCompanyPhone] = useState(
        (authUser.userType === UserType.Company && authUser.companyPhone) || '',
    );
    const updateCompanyPhone = useCallback((event) => {
        setCompanyPhoneError(
            !(parseMax(event.target.value)?.isValid() || !event.target.value.trim().length),
        );

        setCompanyPhone(
            event.target.value.trim(),
        );
    }, []);
    const parsedPhoneNumber = useMemo(() => {
        const asYouType = new AsYouType('US');
        return asYouType.input(companyPhone);
    }, [companyPhone]);

    const [userIconError, setUserIconError] = useState(false);
    const [userIcon, setUserIcon] = useState(
        authUser.iconUrl,
    );
    useEffect(() => {
        setUserIconError(!userIcon);
    }, [userIcon]);

    const [name, setName] = useState(authUser.name);
    const [nameError, setNameError] = useState(false);
    const updateName = useCallback((event) => {
        setNameError(event.target.value.trim().length < 3);

        setName(event.target.value);
    }, []);

    const [userBio, setUserBio] = useState(authUser.bio);
    const handleUserBioChange = useCallback((event) => {
        setUserBio(event.target.value.slice(0, 120));
    }, []);

    const [companyPxId, setCompanyPxId] = useState(
        (authUser.userType === UserType.Company && authUser.companyPxId) || '',
    );
    const [companyPxIdError, setCompanyPxIdError] = useState(false);
    const updateCompanyPxId = useCallback((event) => {
        const newValue = event.target.value.replace(/[^0-9]/g, '');

        const isError = !!(
            newValue.length < 7 && newValue.length
        );

        setCompanyPxIdError(isError);
        setCompanyPxId(newValue);

        if (!isError) {
            updateUserLinks(
                authUser,
                {
                    pixelCode: newValue
                }
            );
        }
    }, []);

    const [companyGaId, setCompanyGaId] = useState(
        (authUser.userType === UserType.Company && authUser.companyGaId) || '',
    );
    const [companyGaIdError, setCompanyGaIdError] = useState(false);
    const updateCompanyGaId = useCallback((event) => {
        const newValue = event.target.value.toUpperCase().replace(/[^UA0-9-]/g, '');

        const isError = !!(
            !newValue.match(/UA-[0-9]{4,8}-[0-9]+/g)
            && newValue.length
        );

        setCompanyGaIdError(isError);
        setCompanyGaId(newValue);

        if (!isError) {
            updateUserLinks(
                authUser,
                {
                    gaId: newValue
                }
            );
        }
    }, []);

    interface ConnectionBase {
        name: string;
        urlRoot: string;
        userPath: 'companyFb' | 'companyIg' | 'companyYt' | 'companyTw' | 'companyTt';
        value: string;
        helperText: string;
        hasError?: boolean;
    }

    const [urlBases, setUrlBases] = useState<ConnectionBase[]>(authUser.userType === UserType.Company ? [
        {
            name: 'YouTube',
            urlRoot: '',
            userPath: 'companyYt',
            value: authUser.companyYt || '',
            helperText: 'Tip: Add your Youtube URL to drive customers to your YouTube channel.',
        }, {
            name: 'Facebook',
            urlRoot: 'facebook.com/',
            userPath: 'companyFb',
            value: authUser.companyFb || '',
            helperText: 'Tip: Add your Facebook Page to drive customers to your business\'s posts.',
        }, {
            name: 'Instagram',
            urlRoot: 'instagram.com/',
            userPath: 'companyIg',
            value: authUser.companyIg || '',
            helperText: 'Tip: Add your Instagram handle to drive customers to your profile.',
        }, {
            name: 'Twitter',
            urlRoot: 'twitter.com/',
            userPath: 'companyTw',
            value: authUser.companyTw || '',
            helperText: 'Tip: Add your Twitter handle to drive customers to your Twitter feed.',
        }, {
            name: 'TikTok',
            urlRoot: 'tiktok.com/@',
            userPath: 'companyTt',
            value: authUser.companyTt || '',
            helperText: 'Tip: Add your TikTok profile to drive customers to your TikTok.',
        },
    ] : []);

    useEffect(
        () => {
            const newPartialUser: Partial<PersonalUser & CompanyUser> = {};
            if (!nameError) {
                newPartialUser.name = name || '';
            }
            if (!userIconError) {
                newPartialUser.iconUrl = userIcon || '';
            }
            if (authUser.userType === UserType.Company) {
                if (!companyPhoneError) {
                    newPartialUser.companyPhone = companyPhone || '';
                }
                if (!companyEmailError) {
                    newPartialUser.companyEmail = companyEmail || '';
                }
                if (!companyWebsiteError) {
                    newPartialUser.companyWebsite = companyWebsite || '';
                }
                if (!companyNameError) {
                    newPartialUser.companyName = companyName || '';
                }

                if (!companyPxIdError) {
                    newPartialUser.companyPxId = companyPxId || '';
                }
                if (!companyGaIdError) {
                    newPartialUser.companyGaId = companyGaId || '';
                }

                urlBases.forEach(base => {
                    if (!base.hasError) {
                        newPartialUser[base.userPath] = base.value || '';
                    }
                });

                newPartialUser.companyAddress = companyAddress || '';
            }
            newPartialUser.bio = userBio || '';

            updateUser(authUser, newPartialUser);
        },
        [
            name, userIcon, userBio, companyPhone, companyEmail, companyWebsite, companyAddress, companyName,
            companyPxId, companyGaId, urlBases,
        ],
    );

    useEffect(() => {
        const container: HTMLInputElement | null = document.querySelector('#algolia-places-search');

        if (container) {
            const placesAutocomplete = places(
                {
                    apiKey: '11ee091659beeaf657a1a728fb283fe3',
                    appId: 'plSAEF8PUN34',
                    type: 'address',
                    style: false,
                    container,
                },
            );

            placesAutocomplete.on('change', (data) => {
                setCompanyAddress(data.suggestion.value);
                setCompanyAddressError(false);
            });

            container.onblur = () => {
                placesAutocomplete.close();
            };
        }
    }, []);

    const handleConnectionChange = useCallback((event, baseName: string) => {
        setUrlBases(
            urlBases.map(
                base =>
                    base.name === baseName
                    ? {
                            ...base,
                            value: base.name === 'YouTube'
                                   ? (
                                       event.target.value
                                   ) : (
                                       event.target.value === base.urlRoot ? '' : base.urlRoot + filterUsername(
                                           event.target.value,
                                           base.urlRoot,
                                       )
                                   ),
                            hasError: base.name === 'YouTube'
                                      ? (
                                          event.target.value.length && (
                                              event.target.value
                                                  .split(
                                                      /(?:^http(?:s?):\/\/(www\.)?youtube\.com\/(?:user|channel)\/)/g,
                                                  )[1] || ''
                                          ).length < 4
                                      ) : (
                                          event.target.value !== base.urlRoot && filterUsername(
                                              event.target.value,
                                              base.urlRoot,
                                          ).length < 4
                                      ),
                        }
                    : base,
            ),
        );
    }, [urlBases]);

    return (
        <div className='settings-page'>
            <div className='user-info'>
                <h1>
                    Your info
                </h1>

                <p className='email'>
                    Email:&nbsp;
                    <strong>
                        {
                            authUser.email
                        }
                    </strong>
                </p>

                {
                    authUser.userType === UserType.Company && (
                        <TextField
                            label='Your name'
                            variant='outlined'
                            onChange={ updateName }
                            value={ name }
                            error={ nameError }
                            className='wide-text-input'
                        />
                    )
                }
            </div>

            <div className='profile-info'>
                <h1>
                    {
                        authUser.userType === UserType.Company ? 'Company info' : 'Profile info'
                    }
                </h1>

                <UserEditCard
                    companyName={ companyName || name }
                    setCompanyName={ setCompanyName || setName }
                    companyNameError={ companyNameError || nameError }
                    setCompanyNameError={ setCompanyNameError || setNameError }
                    userIcon={ userIcon }
                    setUserIcon={ setUserIcon }
                    userIconError={ userIconError }
                    setUserIconError={ setUserIconError }
                    username={ authUser.username || '' }
                    name={ authUser.name }
                    authUser={ authUser }
                    useNameAsCompany={ authUser.userType === UserType.Person }
                    showUsernameTooltip
                    large
                />

                {
                    authUser.userType === UserType.Company && (
                        <>
                            {
                                process.env.REACT_APP_ALLOW_USER_ADDRESS === 'true' && (
                                    <form autoComplete='off'>
                                        <TextField
                                            label='Company address'
                                            variant='outlined'
                                            className='algolia-container wide-text-input'
                                            InputProps={ {
                                                id: 'algolia-places-search',
                                            } }
                                            autoComplete='off'
                                            value={ companyAddress }
                                            onChange={ updateCompanyAddress }
                                            error={ companyAddressError }
                                            inputProps={ {
                                                autoComplete: 'off',
                                                'aria-autocomplete': 'none',
                                            } }
                                        />
                                    </form>
                                )
                            }

                            <TextField
                                label='About You'
                                className='wide-text-input'
                                variant='outlined'
                                value={ userBio }
                                inputProps={ { maxLength: 140 } }
                                onChange={ handleUserBioChange }
                                placeholder='Tip: enter a bio to better describe yourself.'
                            />

                            <TextField
                                label='Company phone number (incl. country code)'
                                variant='outlined'
                                className='wide-text-input'
                                error={ companyPhoneError }
                                value={ parsedPhoneNumber }
                                onChange={ updateCompanyPhone }
                            />

                            <TextField
                                label='Company email address'
                                variant='outlined'
                                className='wide-text-input'
                                value={ companyEmail }
                                error={ companyEmailError }
                                onChange={ updateCompanyEmail }
                            />

                            <TextField
                                label='Company website'
                                variant='outlined'
                                className='wide-text-input'
                                value={ companyWebsite }
                                error={ companyWebsiteError }
                                onChange={ updateCompanyWebsite }
                            />
                        </>
                    )
                }
            </div>

            <div className='links-info'>
                <h1>
                    Your connections
                </h1>

                <TextField
                    label='Facebook Pixel ID'
                    variant='outlined'
                    className='wide-text-input'
                    value={ companyPxId }
                    error={ companyPxIdError }
                    onChange={ updateCompanyPxId }
                    helperText='Tip: Add your Facebook Pixel ID to build a Facebook audience.'
                />

                <TextField
                    label='Google Analytics ID'
                    variant='outlined'
                    className='wide-text-input'
                    value={ companyGaId }
                    error={ companyGaIdError }
                    onChange={ updateCompanyGaId }
                    helperText='Tip: Add your Google Analytics ID to track views.'
                />

                {
                    authUser.userType === UserType.Company && (
                        <hr />
                    )
                }

                {
                    urlBases.map(base => (
                        <TextField
                            label={ `${ base.name } account URL` }
                            variant='outlined'
                            className='wide-text-input'
                            value={ base.value }
                            error={ base.hasError }
                            helperText={ base.helperText }
                            onChange={ (event) =>
                                handleConnectionChange(event, base.name)
                            }
                            onFocus={
                                (e) =>
                                    e.target.select()
                            }
                        />
                    ))
                }
            </div>
        </div>
    );
}
