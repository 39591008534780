import React, { ReactElement, useEffect } from 'react';
import Loader from 'react-loader-spinner';

export default function LoadingPage(): ReactElement {
    useEffect(() => {
        document.body.style.overflow = 'none';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className='loading-page'>
            <Loader
                type='TailSpin'
                color='#00a1ea'
                height={ 100 }
                width={ 100 }
            />
        </div>
    );
}
