import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import TwitterPicker from 'react-color/lib/components/twitter/Twitter';
import { CompletedUser } from '../../types';
import { updateUser } from '../../utils';
import BackgroundEditModal from './BackgroundEditModal';

interface Props {
    authUser: CompletedUser;
}

const fontFamilies = [
    'Cursive',
    'Fantasy',
    'Serif',
    '\'Palatino Linotype\', Georgia, \'Book Antiqua\', Palatino, serif',
    'Baskerville, \'Baskerville Old Face\', \'Goudy Old Style\', Garamond, \'Times New Roman\', serif',
    'Sans-serif',
    '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    '\'Arial Black\', Gadget, sans-serif',
    '\'Trebuchet MS\', \'Lucida Sans Unicode\', Helvetica, sans-serif',
    'Monospace',
    '\'Lucida Console\', Monaco, monospace',
];

const defaultColors = [
    '#545454',
    '#777777',
    '#CE0101',
    '#FF0000',
    '#FF6600',
    '#FF9800',
    '#BCCA00',
    '#E8D100',
    '#10C200',
    '#15FF00',
    '#00B6BA',
    '#00F9FF',
    '#006BBA',
    '#0093FF',
    '#6700A9',
    '#9B00FF',
    '#BC00AD',
    '#FF00EB',
    '#ff3a60',
    '#FFFFFF',
];

const presetBackgroundColors = [
    '#000000',
    '#333333',
    '#cccccc',
    '#ffffff',
    '#DE1A1A',
    '#9b0000',
    '#ff0000',
    '#ff6600',
    '#ffb74e',
    '#ffe600',
    '#0c9400',
    '#10c200',
    '#018285',
    '#00f9ff',
    '#1B2845',
    '#005493',
    '#0093ff',
    '#510085',
    '#9b00ff',
    '#830079',
    '#ff00eb',

    'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(79,79,79,1) 100%)',
    'linear-gradient(180deg, rgba(251,251,237,1) 0%, rgba(248,247,225,1) 50%, rgba(242,238,201,1) 100%)',
    'linear-gradient(0deg, rgba(255,8,245,1) 0%, rgba(190,9,199,1) 56%, rgba(165,41,226,1) 100%)',
];

interface LinkColorPickerProps {
    buttonColor: string;
    setButtonColor: (color: string) => void;
}

function LinkColorPicker(
    {
        buttonColor,
        setButtonColor,
    }: LinkColorPickerProps,
) {
    return (
        <div className='theme-options links'>
            <div className='link-container'>
                <div
                    className='link'
                    style={ {
                        backgroundColor: buttonColor,
                    } }
                >
                    <div className='link-title-placeholder' />
                    <div className='link-url-placeholder' />
                    <div className='link-description-placeholder' />
                </div>
            </div>

            <TwitterPicker
                color={ buttonColor }
                onChange={ (color) => setButtonColor(color.hex) }
                colors={ defaultColors }
            />
        </div>
    );
}

export default function Themes(
    {
        authUser,
    }: Props,
): ReactElement {
    const [selectedBackground, setSelectedBackground] = useState(authUser.theme.background);
    const [buttonColor, setButtonColor] = useState(authUser.theme.linkColor);
    const [featuredButtonColor, setFeaturedButtonColor] = useState(authUser.theme.featuredColor);
    const [selectedFont, setSelectedFont] = useState(authUser.theme.font);

    useEffect(() => {
        updateUser(
            authUser,
            {
                theme: {
                    ...authUser.theme,
                    background: selectedBackground,
                    linkColor: buttonColor,
                    featuredColor: featuredButtonColor,
                    font: selectedFont,
                },
            },
        );
    }, [selectedBackground, buttonColor, selectedFont, featuredButtonColor]);

    const [showBackgroundModal, setShowBackgroundModal] = useState(false);
    const showBackgroundEditor = useCallback(() => {
        setShowBackgroundModal(true);
    }, []);

    const handleModalClose = useCallback((background?: string) => {
        setShowBackgroundModal(false);

        if (background) {
            setSelectedBackground(background);
        }
    }, []);

    const isCustomBackground = !presetBackgroundColors.includes(selectedBackground);

    return (
        <div className='themes-page'>
            <div className='theme-menu'>
                <h1>
                    Background
                </h1>

                <div className='theme-options backgrounds'>
                    <div
                        className={ `phone${ (isCustomBackground ? ' selected' : '') }` }
                        style={ {
                            border: '2px dashed rgba(13, 13, 13, 0.6)',
                            background: isCustomBackground ? selectedBackground : 'transparent',
                            padding: isCustomBackground ? 0 : 2,
                        } }
                        onClick={ showBackgroundEditor }
                    >
                        {
                            !isCustomBackground
                            && <p>Select custom background...</p>
                        }
                    </div>
                    {
                        presetBackgroundColors.map(
                            color => (
                                <div
                                    className={ `phone${ selectedBackground === color ? ' selected' : '' }` }
                                    style={ {
                                        background: color,
                                    } }
                                    onClick={ () => {
                                        setSelectedBackground(color);
                                    } }
                                />
                            ))
                    }
                </div>
            </div>
            <div className='theme-menu'>
                <h1>
                    Links
                </h1>

                <h2>Normal links</h2>
                <LinkColorPicker
                    buttonColor={ buttonColor }
                    setButtonColor={ setButtonColor }
                />

                <h2>Pinned links</h2>
                <LinkColorPicker
                    buttonColor={ featuredButtonColor }
                    setButtonColor={ setFeaturedButtonColor }
                />
            </div>
            <div className='theme-menu'>
                <h1>
                    Fonts
                </h1>

                <div className='theme-options fonts'>
                    {
                        fontFamilies.map(
                            font => (
                                <div
                                    className={ `font${ selectedFont === font ? ' selected' : '' }` }
                                    onClick={ () => setSelectedFont(font) }
                                >
                                    <span
                                        className='font-preview'
                                        style={ {
                                            fontFamily: font.toLowerCase(),
                                        } }
                                    >
                                        Aa
                                    </span>
                                    <span className='font-name'>
                                        {
                                            font
                                                .split(',')[0]
                                                .replace(/['"]/g, '')
                                        }
                                    </span>
                                </div>
                            ),
                        )
                    }
                </div>
            </div>
            {
                showBackgroundModal && (
                    <BackgroundEditModal
                        handleClose={ handleModalClose }
                        initialValue={ isCustomBackground ? selectedBackground : '#ffffff' }
                    />
                )
            }
        </div>
    );
}
