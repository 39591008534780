import React, { ReactElement, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { IncreaseRangeTab } from '../profile/stats/Stats';

interface Props {
    timestamps: number[][],
    labels: string[],
    forceDataMap?: [number, number][][];
    groupBy?: IncreaseRangeTab,
}

export const useDataTimeMap = (
    data: number[][],
    updater?: string | number | (string | number | undefined)[],
    groupBy?: IncreaseRangeTab,
): [number, number][][] => {
    return useMemo(() => {
        const endTime = new Date().getTime();

        if (groupBy !== undefined) {
            const groupPeriod = [1, 7, 31, 365][groupBy] * 24 * 60 * 60 * 1000;
            const allBreakpoints = data
                .map((series): number[] => {
                    const breakpointsHere = [series.sort()[0]];

                    while (breakpointsHere.slice(-1)[0] <= endTime) {
                        breakpointsHere.push(
                            breakpointsHere.slice(-1)[0] + groupPeriod,
                        );
                    }

                    breakpointsHere.push(endTime);

                    return breakpointsHere.sort();
                });

            const startPoints = allBreakpoints.map(breakpointList => breakpointList[0]);
            const breakpoints = allBreakpoints[startPoints.indexOf(Math.min(...startPoints))];

            return data.map(
                (series): [number, number][] =>
                    breakpoints.slice(0, -1).map(
                        (breakpoint, index) =>
                            [
                                breakpoint,
                                series
                                    .filter(
                                        item => item >= breakpoint && item < breakpoints[index + 1],
                                    )
                                    .length,
                            ],
                    ));
        }

        const earliestDataPoint = Math.min(...data.flat());
        return data.map((series): [number, number][] => {
            const dataPoints: [number, number][] = [];

            if (Math.min(...series) > earliestDataPoint) {
                dataPoints.push([earliestDataPoint, 0]);
            }

            series.sort().forEach((doc, index) => {
                dataPoints.push([doc, index + 1]);
            });

            dataPoints.push([endTime, series.length]);

            return dataPoints;
        });
    }, typeof updater === 'object' ? updater.concat(groupBy) : [updater, groupBy]);
};

export default function TimestampChart(
    {
        timestamps,
        labels,
        forceDataMap,
        groupBy,
    }: Props,
): ReactElement {
    const dataTimeMap = useDataTimeMap(timestamps, timestamps.map(series => series.length), groupBy);
    const dataMap = forceDataMap || dataTimeMap;

    if (!dataMap.some(series => series.length)) {
        return (
            <p className='chart-no-data'>
                Oops! It appears you don't have any link data to show yet. Try creating a link to get started.
            </p>
        );
    }

    return <Chart
        type='area'
        height={ 280 }
        options={ {
            chart: {
                width: '80%',
                height: 280,
                stacked: true,
            },
            stroke: {
                curve: groupBy === undefined ? 'smooth' : 'stepline',
            },
            xaxis: {
                type: 'datetime',
                datetimeUTC: true,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm',
                },
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
            },
        } }
        series={ dataMap.map(
            (series, index) =>
                ({
                    name: labels[index],
                    data: series,
                }),
        ) }
    />;
}
