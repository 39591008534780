import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faClipboard, faStar } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons/faGripHorizontal';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from '@material-ui/core/Switch/Switch';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import AnalyticsModal from '../profile/links/AnalyticsModal';
import ConfirmDeleteModal from '../profile/links/ConfirmDeleteModal';
import { BasicLinkDoc, isCompletedUser, Link, LinkDoc, LinkPageSettings, User } from '../types';
import {
    appendQueryParameter,
    cleanFbUpdateObj,
    getOrCreateLinkDoc,
    handleLinkClickUpdate,
    switchLightDark,
    updateUser
} from '../utils';
import { useSnackbar } from 'notistack';
import copyTextToClipboard from 'copy-text-to-clipboard';

interface Props {
    link: Link,
    setLink?: (newLink: Link) => void;
    editable: boolean;
    deleteLink?: () => void;
    theme?: LinkPageSettings;
    registerAnalytics?: boolean;
    forceAnalyticsTime?: number;
    authUser?: User;
}

function useFavicon(siteUrl: string): string | null {
    const [url, setUrl] = useState<string | null>(null);

    useEffect(() => {
        if (url) {
            const parsedUrl = new URL(siteUrl);
            const shortUrl = parsedUrl.hostname;

            fetch(`http://favicongrabber.com/api/grab/${shortUrl}`)
                .then((response) => {
                    response
                        .json()
                        .then(json => {
                            let bestIcon = json.icons[0];
                            json.icons.forEach((icon: any) => {
                                if (icon.sizes && (
                                    !bestIcon.sizes
                                    || parseInt(icon.sizes.split('x')[0]) > parseInt(bestIcon.sizes.split('x')[0])
                                )) {
                                    bestIcon = icon;
                                }
                            });

                            setUrl(bestIcon.src);
                        });
                });
        } else {
            setUrl(null);
        }
    }, [siteUrl, url]);

    return url;
}

export default function LinkItem(
    {
        link,
        setLink,
        editable,
        deleteLink,
        theme,
        registerAnalytics,
        forceAnalyticsTime,
        authUser,
    }: Props,
): ReactElement {
    const handleFieldChange = useCallback(function <T extends keyof Link>(field: T) {
        return (event: any) => {
            const newLink: Link = Object.assign({}, link);
            newLink[field] = event.target.value as Link[T];

            setLink && setLink(newLink);
        };
    }, [link, setLink]);

    const pinLink = useCallback(() => {
        setLink && setLink(
            {
                ...link,
                isPriority: !link.isPriority,
            },
        );
    }, [setLink, link]);

    const toggleLink = useCallback(() => {
        setLink && setLink(
            {
                ...link,
                disabled: !link.disabled,
            },
        );
    }, [setLink, link]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);

    const favicon = useFavicon(link.url);

    const [linkDoc, setLinkDoc] = useState<BasicLinkDoc | null>(null);
    const getLinkDoc = useCallback((callback?: (data: BasicLinkDoc) => void) => {
        getOrCreateLinkDoc(link.id, authUser, (snapshot) => {
            callback && callback(snapshot);
        });
    }, [link.id]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const updateLinkDoc = useCallback((newData: Partial<LinkDoc>, callback?: () => void) => {
        cleanFbUpdateObj(newData)
            .then((cleanData) => {
                firebase
                    .firestore()
                    .collection('links')
                    .doc(link.id)
                    .update(cleanData)
                    .then(() => {
                        callback && callback();
                    })
                    .catch(() => {
                        enqueueSnackbar('Something went wrong with a link.', {
                            variant: 'warning',
                        });
                    });
            });
    }, [link.id]);

    useEffect(() => {
        getLinkDoc((data) => {
            setLinkDoc(data);

            if (registerAnalytics) {
                updateLinkDoc(
                    {
                        views: data.views.concat(forceAnalyticsTime || new Date().getTime()),
                    },
                );
            }
        });
    }, [forceAnalyticsTime, getLinkDoc, registerAnalytics, updateLinkDoc]);

    const handleLinkClick = useCallback(() => {
        if (registerAnalytics) {
            if (linkDoc) {
                handleLinkClickUpdate(linkDoc, link);
            } else {
                getLinkDoc((data) => {
                    setLinkDoc(data);
                    handleLinkClickUpdate(data, link);
                });
            }
        }

        window.open(appendQueryParameter(link.url, 'utm_source', 'fitlinkio'), '_blank');
    }, [getLinkDoc, link, linkDoc, registerAnalytics, updateLinkDoc]);

    const handleDeleteLink = useCallback(() => {
        firebase
            .firestore()
            .collection('links')
            .doc(link.id)
            .delete()
            .then(() => {
                deleteLink && deleteLink();
            });
    }, [deleteLink, link.id]);

    const [hovering, setHovering] = useState(0);
    const shortStub = `https://fitlink.io/${authUser && isCompletedUser(authUser) ? `${authUser.username}/` : ''}`;

    const copyShortUrl = useCallback(() => {
        setHovering(0);

        const onSuccess = (shortenedAs: string) => {
            copyTextToClipboard(`${shortStub}${shortenedAs}`);
            enqueueSnackbar('Copied shortened URL to clipboard!', {
                variant: 'success',
                preventDuplicate: true,
                key: shortenedAs,
            });
        };

        if (link.shortenedAs) {
            onSuccess(link.shortenedAs);
        } else {
            enqueueSnackbar('Generating shortened URL...', {
                variant: 'info',
                key: link.id,
            });

            if (authUser && isCompletedUser(authUser)) {
                updateUser(authUser, {
                    links: authUser.links,
                }, (updateObj) => {
                    closeSnackbar(link.id);
                    onSuccess(updateObj.links.filter(e => e.id === link.id)[0].shortenedAs);
                });
            }
        }
    }, [link, authUser]);

    return (
        <div
            className={
                `link${link.isPriority ? ' priority' : ''}${theme ? ' auto' : ''}${editable ? ' editable' : ''}`
            }
            onClick={() => !editable && handleLinkClick()}
            style={{
                background: link.isPriority ? (theme?.featuredColor || '') : (theme?.linkColor || ''),
                fontFamily: theme?.font || '',
            }}
        >
            {
                !editable && favicon && (
                    <img
                        className='link-favicon'
                        src={favicon}
                        alt={`${link.url} favicon`}
                    />
                )
            }

            {
                editable && (
                    <div className='link-options'>
                        <FontAwesomeIcon
                            className='link-grip'
                            icon={faGripHorizontal}
                        />

                        <button
                            className={`pin-link${link.isPriority ? ' pinned' : ''}`}
                            onClick={pinLink}
                            data-tip='Pin link?'
                        >
                            <FontAwesomeIcon icon={link.isPriority ? faStar : faStarRegular} />
                        </button>

                        <button
                            className='remove-link'
                            onClick={() => setShowDeleteModal(true)}
                            data-tip='Delete link'
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>

                        {/*<button*/}
                        {/*    className='copy-link'*/}
                        {/*    onClick={ copyShortUrl }*/}
                        {/*    data-tip='Copy shortened link'*/}
                        {/*>*/}
                        {/*    <FontAwesomeIcon icon={ faClipboard } />*/}
                        {/*</button>*/}

                        <Switch
                            checked={!link.disabled}
                            onClick={toggleLink}
                            className='toggle-link'
                            color='primary'
                            data-tip='Show on Fitlink page?'
                        />

                        <button
                            className='link-analytics'
                            onClick={() => setShowAnalyticsModal(true)}
                            data-tip='View link analytics'
                        >
                            <FontAwesomeIcon icon={faChartLine} />
                        </button>
                    </div>
                )
            }

            {
                editable ? (
                    <input
                        type='text'
                        className='link-title'
                        value={link.title}
                        onChange={handleFieldChange('title')}
                        placeholder='Enter a link title...'
                        autoFocus={true}
                    />
                ) : (
                    <p
                        className='link-title'
                        style={{
                            color: theme
                                   ? switchLightDark(
                                    link.isPriority ? theme.featuredColor : theme.linkColor,
                                    '#ffffff',
                                    '#000000',
                                )
                                   : '',
                        }}
                    >
                        {link.title}
                    </p>
                )
            }

            {
                editable && (
                    <div className='link-url-container'>
                        <input
                            type='text'
                            className='link-url'
                            value={
                                !link.shortenedAs || !link.url.trim() || hovering
                                ? link.url
                                : `${shortStub}${link.shortenedAs}`
                            }
                            onChange={handleFieldChange('url')}
                            placeholder='Link destination URL'
                            onMouseEnter={() => setHovering(1)}
                            onMouseLeave={() => hovering === 1 && setHovering(0)}
                            onFocus={() => setHovering(2)}
                            onBlur={() => hovering === 2 && setHovering(0)}
                        />
                        <button
                            className='inline-copy-link'
                            onClick={copyShortUrl}
                            data-tip='Copy shortened link'
                            disabled={!link.url.trim()}
                        >
                            <FontAwesomeIcon icon={faClipboard} />
                        </button>
                    </div>
                )
            }

            {
                editable ? (
                    <textarea
                        className='link-description'
                        value={link.description}
                        onChange={handleFieldChange('description')}
                        placeholder='Enter a link description...'
                    />
                ) : (
                    link.description.trim() && (
                        <p
                            className='link-description'
                            style={{
                                color: theme
                                       ? switchLightDark(
                                        link.isPriority ? theme.featuredColor : theme.linkColor,
                                        '#ffffff',
                                        '#000000',
                                    )
                                       : '',
                            }}
                        >
                            {link.description}
                        </p>
                    )
                )
            }
            {
                showDeleteModal && deleteLink && (
                    <ConfirmDeleteModal
                        link={link}
                        deleteLink={handleDeleteLink}
                        closeModal={() => setShowDeleteModal(false)}
                    />
                )
            }
            {
                showAnalyticsModal && (
                    <AnalyticsModal
                        link={link}
                        linkDoc={linkDoc}
                        closeModal={() => setShowAnalyticsModal(false)}
                    />
                )
            }
        </div>
    );
}
