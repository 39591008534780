import { Dialog, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { ReactElement } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import { BasicLinkDoc, Link } from '../../types';
import TimestampChart from '../../ui/TimestampChart';

interface Props {
    link: Link;
    linkDoc: BasicLinkDoc | null;
    closeModal: () => void;
}

export default function AnalyticsModal(
    {
        link,
        linkDoc,
        closeModal,
    }: Props,
): ReactElement {
    const linkName = link.title
                     ? (
                         <strong>
                             '
                             {
                                 link.title
                             }
                             '
                         </strong>
                     )
                     : (
                         <em>
                             untitled
                         </em>
                     );

    const ctr = linkDoc ? (Number.isFinite(linkDoc.clicks.length / linkDoc.views.length)
                           ? linkDoc.clicks.length / linkDoc.views.length * 100
                           : 0) : 0;

    return (
        <Dialog
            open={ true }
            className='link-analytics-modal'
        >
            <DialogTitle>
                { linkName } link stats
            </DialogTitle>
            <DialogContent>
                {
                    linkDoc ? (
                        <DialogContentText>
                            {
                                ctr >= 2 ? (
                                    <>
                                    <span className='success'>
                                        {
                                            ctr < 4
                                            ? <>The link { linkName } is doing good!</>
                                            : <>The link { linkName } is doing amazing!</>
                                        }
                                    </span>
                                        &nbsp;It currently has:
                                    </>
                                ) : <>The link { linkName } currently has:</>
                            }

                            <ul>
                                <li>
                                    <strong>{ linkDoc.views.length }</strong>
                                    &nbsp;views
                                </li>
                                <li>
                                    <strong>{ linkDoc.clicks.length }</strong>
                                    &nbsp;clicks
                                </li>
                                <li>
                                    A&nbsp;
                                    <em
                                        data-tip='Click-through rate - the percentage of people who saw the link who clicked on it'
                                        style={ {
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        } }
                                    >
                                        CTR
                                    </em>
                                    &nbsp;of&nbsp;
                                    <strong>
                                        { ctr.toFixed(2) }%
                                    </strong>
                                </li>
                            </ul>
                            <ReactTooltip
                                effect='solid'
                                place='bottom'
                            />

                            <TimestampChart
                                timestamps={ linkDoc ? [linkDoc.views, linkDoc.clicks] : [] }
                                labels={ ['Views', 'Clicks'] }
                            />
                        </DialogContentText>
                    ) : (
                        <div
                            style={ {
                                width: 80,
                                margin: '0 auto',
                            } }
                        >
                            <Loader
                                type='TailSpin'
                                color='#00a1ea'
                                height={ 80 }
                                width={ 80 }
                            />
                        </div>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={ closeModal }
                    color='primary'
                    autoFocus
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
