import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography/Typography';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Links from './profile/links/Links';
import Settings from './profile/Settings';
import Stats from './profile/stats/Stats';
import Themes from './profile/themes/Themes';
import { isCompletedUser, User } from './types';
import LoadingPage from './ui/LoadingPage';
import Navbar from './ui/Navbar';
import ViewLinks from './ViewLinks';


interface Props {
    authUser: User | null;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={ value !== index }
            { ...other }
        >
            { value === index && (
                <Box p={ 3 }>
                    <Typography>{ children }</Typography>
                </Box>
            ) }
        </div>
    );
}

interface LinkTabProps {
    label?: string;
    href?: string;
}

function LinkTab(props: LinkTabProps) {
    const reactHistory = useHistory();

    return (
        <Tab
            component='a'
            onClick={ (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                event.preventDefault();

                if (props.href) {
                    reactHistory.push(props.href);
                }
            } }
            { ...props }
        />
    );
}

export default function EditProfile(
    {
        authUser,
    }: Props,
): ReactElement {
    const [tab, setTab] = useState(0);
    const handleTabChange = useCallback((event, newValue) => {
        setTab(newValue);
    }, []);

    useEffect(() => {
        if (!['#links', '#themes', '#settings', '#stats'].includes(window.location.hash)) {
            reactHistory.push('#links');
        }
    });

    const handleHashChange = useCallback(() => {
        if (window.location.hash) {
            switch (window.location.hash) {
                case '#links':
                    setTab(0);
                    break;

                case '#themes':
                    setTab(1);
                    break;

                case '#settings':
                    setTab(2);
                    break;

                case '#stats':
                    setTab(3);
                    break;

                default:
                    setTab(0);
                    break;
            }
        }
    }, []);

    useEffect(() => {
        handleHashChange();

        window.onhashchange = handleHashChange;
    }, []);

    useEffect(() => {
        document.title = `Fitlink - Edit ${ ['links', 'themes', 'settings', 'stats'][tab] }`;
    }, [tab]);

    const reactHistory = useHistory();
    if (!authUser || !isCompletedUser(authUser)) {
        reactHistory.push('/');
    }

    if (authUser && isCompletedUser(authUser)) {
        return (
            <div className='profile-page'>
                <Navbar
                    authUser={ authUser }
                    page='Dashboard'
                />
                <AppBar position='sticky'>
                    <Tabs
                        variant='fullWidth'
                        value={ tab }
                        onChange={ handleTabChange }
                    >
                        <LinkTab
                            label='Links'
                            href='#links'
                        />
                        <LinkTab
                            label='Themes'
                            href='#themes'
                        />
                        <LinkTab
                            label='Settings'
                            href='#settings'
                        />
                        <LinkTab
                            label='Stats'
                            href='#stats'
                        />
                    </Tabs>
                </AppBar>
                <div className='profile-contents'>
                    <div className='edit-profile'>
                        <TabPanel
                            value={ tab }
                            index={ 0 }
                        >
                            <Links authUser={ authUser } />
                        </TabPanel>
                        <TabPanel
                            value={ tab }
                            index={ 1 }
                        >
                            <Themes authUser={ authUser } />
                        </TabPanel>
                        <TabPanel
                            value={ tab }
                            index={ 2 }
                        >
                            <Settings authUser={ authUser } />
                        </TabPanel>
                        <TabPanel
                            value={ tab }
                            index={ 3 }
                        >
                            <Stats authUser={ authUser } />
                        </TabPanel>
                    </div>
                    <div className='profile-preview'>
                        <ViewLinks forceUsername={ authUser.username } />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <LoadingPage />
    );
}
