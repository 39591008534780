import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Step, StepLabel } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade/Fade';
import FormLabel from '@material-ui/core/FormLabel';
import Modal from '@material-ui/core/Modal/Modal';
import Stepper from '@material-ui/core/Stepper/Stepper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { CompletedUser, User, UserType } from '../../types';
import { asyncSome, cleanUsername, updateUser, usernameIsTaken } from '../../utils';

import gray from '../img/gray.jpg';
import UserEditCard from '../UserEditCard';
import GMBDialog from './GMBDialog';

import { Combination } from 'js-combinatorics';

interface Props {
    authUser: User;
}

enum Pages {
    You,
    Company,
    Username,
    GoogleAuth,
    Finish,
}

function getUsernameVariants(length: number): Combination<string> {
    const characters = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ._~-0123456789`.split('');
    return new Combination(characters, length);
}

export default function SignUpModal(
    {
        authUser,
    }: Props,
): ReactElement {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const cancelSignUp = useCallback(() => {
        setLoading(true);
        firebase
            .auth()
            .signOut()
            .then(() => {
                enqueueSnackbar('Cancelled sign-up: select an account to start over', {
                    variant: 'info',
                });
            });
    }, [enqueueSnackbar]);

    const [showGMBInfo, setShowGMBInfo] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [name, setName] = useState(authUser.name);
    const updateName = useCallback((event) => {
        setName(event.target.value);
        setNameError(false);
    }, []);

    const [userType, setUserType] = useState(UserType.Company);
    const handleUserTypeChange = useCallback((event) => {
        setUserType(event.target.checked ? UserType.Company : UserType.Person);
    }, []);

    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyName, setCompanyName] = useState('');

    const [userIconError, setUserIconError] = useState(false);
    const [userIcon, setUserIcon] = useState(authUser.iconUrl || gray);

    const [usernameError, setUsernameError] = useState(false);
    const [username, setUsername] = useState('');
    const updateUsername = useCallback((event) => {
        setUsername(
            cleanUsername(event.target.value)
        );
        setUsernameError(false);
    }, []);

    useEffect(() => {
        async function findUsername() {
            let length = 0;
            let success = false;
            while (!success && length <= 6) {
                const promises: Promise<Boolean>[] = [
                    usernameIsTaken(
                        cleanUsername(name)
                    )
                ];
                const variants = getUsernameVariants(length);
                for (const suffix of variants) {
                    promises.push(
                        usernameIsTaken(
                            cleanUsername(name) + suffix
                        )
                    );
                }

                await Promise
                    .all(promises)
                    .then(res => {
                        if (res.some(e => !e)) {
                            success = true;
                            setUsername(
                                cleanUsername(name) + variants.nth(res.indexOf(false))
                            );
                        } else {
                            length++;
                        }
                    });
            }
        }

        findUsername();
    }, [name]);

    const [hasValidGMBAccount, setHasValidGMBAccount] = useState(false);
    const [initUrl, setInitUrl] = useState('');
    const [initPhoneNumber, setInitPhoneNumber] = useState('');
    const handleGMBResponse = useCallback((response) => {
        if (response.error) {
            enqueueSnackbar(
                'Something went wrong authorizing your Google My Business account. Try again.',
                {
                    variant: 'error',
                },
            );
            return;
        }

        if (response.accessToken) {
            fetch('https://mybusiness.googleapis.com/v4/accounts', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${ response.accessToken }`,
                },
            })
                .then((rawResponse) => {
                    rawResponse
                        .json()
                        .then(async (json) => {
                            const foundGMBAccounts = json.accounts.filter(
                                (business: any) =>
                                    ['LOCATION_GROUP', 'USER_GROUP', 'ORGANIZATION', 'PERSONAL'].includes(business.type),
                            );

                            if (!foundGMBAccounts.length) {
                                enqueueSnackbar(
                                    'Oops! No valid personal or organizational Google My Business accounts were found linked to that Google Account.',
                                    {
                                        variant: 'warning',
                                    },
                                );
                                return;
                            }

                            const foundGoodLocation = await asyncSome(
                                foundGMBAccounts,
                                async (account: any) => {
                                    const locationSet = await fetch(
                                        `https://mybusiness.googleapis.com/v4/${ account.name }/locations`,
                                        {
                                            method: 'GET',
                                            headers: {
                                                'Authorization': `Bearer ${ response.accessToken }`,
                                            },
                                        },
                                    );

                                    const locationSetJson = await locationSet.json();

                                    if (!locationSetJson?.locations) {
                                        return false;
                                    }

                                    return locationSetJson
                                        .locations
                                        .some(
                                            (location: any) => {
                                                if (location?.locationState?.isVerified) {
                                                    if (userType === UserType.Company) {
                                                        if (location.locationName) {
                                                            setCompanyName(location.locationName);
                                                        }

                                                        if (location.websiteUrl) {
                                                            setInitUrl(account.websiteUrl || '');
                                                        }

                                                        if (location.primaryPhone) {
                                                            setInitPhoneNumber(location.primaryPhone || '');
                                                        }
                                                    }

                                                    setHasValidGMBAccount(true);
                                                    setActiveStep(Pages.Company);
                                                    return true;
                                                }

                                                return false;
                                            });
                                },
                            );

                            if (!foundGoodLocation) {
                                enqueueSnackbar(
                                    `No authorized GMB locations were found associated to ${ response.profileObj.email }.`,
                                    {
                                        variant: 'warning',
                                    },
                                );
                                return;
                            }
                        });
                });
        }
    }, [enqueueSnackbar, userType]);

    const [clickPage, setClickPage] = useState(0);
    const [activeStep, setActiveStep] = useState(process.env.REACT_APP_HIDE_GMB === 'true' ? 1 : 0);
    const steps = [
        ...(process.env.REACT_APP_HIDE_GMB === 'true' ? [] : ['You']),
        userType === UserType.Company ? 'Your company' : 'Your profile',
        'Username & URL',
        ...(process.env.REACT_APP_HIDE_GMB === 'true' ? [] : ['Connect to Google My Business']),
        'Finish',
    ];

    const handleBack = useCallback(() => {
        setClickPage(clickPage - 1);

        if (process.env.REACT_APP_HIDE_GMB === 'true' && activeStep === Pages.Finish) {
            setActiveStep(Pages.Username);
            return;
        }

        setActiveStep(activeStep - 1);
    }, [activeStep, clickPage]);

    const reactHistory = useHistory();
    const handleForward = useCallback(
        () => {
            if (activeStep === Pages.You) {
                if (name.trim().length < 3) {
                    setNameError(true);
                    return;
                }
            }

            if (activeStep === Pages.Username) {
                if (username.trim().length < 3) {
                    setUsernameError(true);
                    return;
                }

                setLoading(true);
                usernameIsTaken(username)
                    .then(taken => {
                        setLoading(false);

                        if (taken) {
                            setUsernameError(true);
                        } else {
                            if (process.env.REACT_APP_HIDE_GMB === 'true') {
                                setActiveStep(Pages.Finish);
                                setClickPage(clickPage + 1);
                                return;
                            }

                            setActiveStep(2);
                            setClickPage(clickPage + 1);
                        }
                    });
            } else {
                if (activeStep === Pages.Company || (process.env.REACT_APP_HIDE_GMB === 'true' && activeStep === Pages.You)) {
                    if (!userIcon || userIcon.startsWith('/')) {
                        setUserIconError(true);
                        return;
                    }

                    if (userType === UserType.Company && companyName.trim().length < 3) {
                        setCompanyNameError(true);
                        return;
                    }
                }

                if (activeStep === Pages.GoogleAuth) {
                    if (!hasValidGMBAccount && process.env.REACT_APP_ALLOW_SKIP_GMB !== 'true') {
                        enqueueSnackbar('You must give access to a valid GMB account before continuing.', {
                            variant: 'warning',
                            preventDuplicate: true,
                        });
                        return;
                    }
                }

                if (activeStep === Pages.You && process.env.REACT_APP_HIDE_GMB === 'true') {
                    setActiveStep(activeStep + 2);
                    setClickPage(clickPage + 1);
                    return;
                }

                if (activeStep === Pages.You || activeStep === Pages.Company || activeStep === Pages.GoogleAuth) {
                    setActiveStep(activeStep + 1);
                    setClickPage(clickPage + 1);
                    return;
                }

                if (activeStep === Pages.Finish) {
                    setLoading(true);

                    updateUser(
                        authUser,
                        {
                            name,
                            userType,
                            username,
                            iconUrl: userIcon,
                            companyWebsite: initUrl,
                            companyPhone: initPhoneNumber,
                            companyName,
                            completedSignUp: true,
                            links: [],
                            bio: '',
                            theme: {
                                background: '#ffffff',
                                font: 'sans-serif',
                                linkColor: '#00B6BA',
                                featuredColor: '#E8D100',
                            },
                        } as Partial<CompletedUser>,
                    )
                        .then(() => {
                            setLoading(false);
                            reactHistory.push('/profile#settings');
                            setIsOpen(false);
                        })
                        .catch(() => {
                            enqueueSnackbar('Something went wrong. Please try again.', {
                                variant: 'error',
                            });
                        });
                }
            }
        },
        [activeStep, clickPage, name, username, userIcon, userType, companyName, hasValidGMBAccount, enqueueSnackbar, authUser, initUrl, initPhoneNumber, reactHistory],
    );

    if (isOpen) {
        return (
            <Modal
                className='modal sign-up'
                open={ isOpen }
                closeAfterTransition
                BackdropComponent={ Backdrop }
                BackdropProps={ {
                    timeout: 500,
                } }
            >
                <Fade in>
                    <div className='modal-content'>
                        <h2>
                            Set up your account
                            <button
                                className='exit-sign-up'
                                onClick={ cancelSignUp }
                                disabled={ loading }
                            >
                                Cancel setup
                            </button>
                        </h2>
                        <p className='subtitle'>We just need some extra info about you before we get started.</p>

                        <Stepper
                            activeStep={ clickPage }
                            alternativeLabel
                        >
                            { steps.map((label) => (
                                <Step key={ label }>
                                    <StepLabel>{ label }</StepLabel>
                                </Step>
                            )) }
                        </Stepper>

                        <form onSubmit={ (e) => e.preventDefault() }>
                            {
                                activeStep === Pages.You && process.env.REACT_APP_HIDE_GMB !== 'true' && (
                                    <>
                                        <TextField
                                            label='Email address'
                                            variant='outlined'
                                            disabled
                                            value={ authUser.email }
                                            data-tip='Your email address is set by your Google account and cannot be changed.'
                                        />
                                        <ReactTooltip effect='solid' />

                                        <TextField
                                            label='Your name'
                                            error={ nameError }
                                            variant='outlined'
                                            onChange={ updateName }
                                            value={ name }
                                            inputProps={ {
                                                name: '',
                                            } }
                                        />

                                        {
                                            process.env.REACT_APP_ALLOW_USER_TYPE_TOGGLE === 'true' && (
                                                <div className='user-type-select'>
                                                    <Grid
                                                        component='label'
                                                        container
                                                        alignItems='center'
                                                        spacing={ 1 }
                                                    >
                                                        <FormLabel>
                                                            I am a...
                                                        </FormLabel>
                                                        <Grid item>Person</Grid>
                                                        <Grid item>
                                                            <Switch
                                                                checked={ userType === UserType.Company }
                                                                onChange={ handleUserTypeChange }
                                                            />
                                                        </Grid>
                                                        <Grid item>Company</Grid>
                                                    </Grid>
                                                </div>
                                            )
                                        }
                                    </>
                                )
                            }

                            {
                                activeStep === Pages.Username && (
                                    <>
                                        <TextField
                                            label='Username'
                                            variant='outlined'
                                            error={ usernameError }
                                            helperText={ usernameError && 'That username is invalid or already taken.' }
                                            onChange={ updateUsername }
                                            value={ username }
                                            inputProps={ {
                                                name: '',
                                            } }
                                            onKeyDown={ (e) => e.charCode || e.keyCode === 13 && e.preventDefault() }
                                        />

                                        <p>
                                            Your Fitlink URL will be:&nbsp;
                                            <strong>
                                                fitlink.io/
                                                {
                                                    username
                                                    || (
                                                        <em>
                                                            &lt;your username&gt;
                                                        </em>
                                                    )
                                                }
                                            </strong>
                                        </p>
                                    </>
                                )
                            }

                            {
                                (
                                    activeStep === Pages.Company
                                    || (
                                        activeStep === Pages.You
                                        && process.env.REACT_APP_HIDE_GMB === 'true'
                                    )
                                ) && (
                                    <UserEditCard
                                        companyName={ companyName }
                                        setCompanyName={ setCompanyName }
                                        companyNameError={ companyNameError }
                                        userIcon={ userIcon }
                                        setUserIcon={ setUserIcon }
                                        userIconError={ userIconError }
                                        username={ username }
                                        setLoading={ setLoading }
                                        authUser={ authUser }
                                        userType={ userType }
                                        name={ name }
                                        setCompanyNameError={ setCompanyNameError }
                                        setUserIconError={ setUserIconError }
                                    />
                                )
                            }

                            {
                                activeStep === Pages.GoogleAuth && (
                                    <div className='long-text-container gmb-auth'>
                                        <p>
                                            To verify your account and confirm your status as a member of a company,
                                            connect your Google My Business account to fitlink.io.
                                            <button
                                                className='link-button'
                                                onClick={ () => setShowGMBInfo(true) }
                                                type='button'
                                            >
                                                Learn more
                                            </button>
                                        </p>
                                        <GoogleLogin
                                            clientId='161314357980-497fg12rdu5ag9e9m0ud2hmp14e6od14.apps.googleusercontent.com'
                                            buttonText='Authorize GMB'
                                            onSuccess={ handleGMBResponse }
                                            onFailure={ handleGMBResponse }
                                            theme='dark'
                                            scope='https://www.googleapis.com/auth/business.manage'
                                        />
                                    </div>
                                )
                            }

                            {
                                activeStep === Pages.Finish && (
                                    <div className='long-text-container'>
                                        <p>
                                            <strong>
                                                Success! You've entered all of info required to get started.
                                            </strong>
                                        </p>
                                        <p>
                                            Take a look at your dashboard, where you can further customise your profile.
                                            This is where you'll be able to customise your links, as well as the look
                                            and
                                            settings of your page.
                                        </p>
                                    </div>
                                )
                            }
                        </form>
                        <div className='modal-controls'>
                            {
                                !(activeStep === Pages.You || (process.env.REACT_APP_HIDE_GMB === 'true' && activeStep === Pages.Company)) && (
                                    <Button
                                        onClick={ handleBack }
                                        variant='contained'
                                        className='back'
                                        color='secondary'
                                        disabled={ loading }
                                    >
                                        <FontAwesomeIcon icon={ faArrowLeft } />
                                        &nbsp;Back
                                    </Button>
                                )
                            }

                            <Button
                                onClick={ handleForward }
                                variant='contained'
                                className='forwards'
                                color='primary'
                                disabled={ loading }
                            >
                                {
                                    activeStep === Pages.Finish
                                    ? 'Go to dashboard'
                                    : (
                                        <>
                                            Next&nbsp;
                                            <FontAwesomeIcon icon={ faArrowRight } />
                                        </>
                                    )
                                }
                            </Button>
                        </div>
                        <GMBDialog
                            showGMBInfo={ showGMBInfo }
                            setShowGMBInfo={ setShowGMBInfo }
                        />
                    </div>
                </Fade>
            </Modal>
        );
    }

    return <></>;
}
