import React, { ReactElement, useEffect } from 'react';
import { User } from './types';
import Hero from './ui/Hero';


import demo1 from './ui/img/demo/social-icons.png';
import demo2 from './ui/img/demo/desktop-product-shot-ttl.png';
import demo3 from './ui/img/demo/3.svg';
import Navbar from './ui/Navbar';

interface Props {
    authUser: User | null;
    signIn: () => void;
}

export default function Home(
    {
        authUser,
        signIn,
    }: Props,
): ReactElement {
    useEffect(() => {
        document.title = 'Fitlink - list & share links all in one place';
    }, []);

    return (
        <div>
            <Navbar
                authUser={ authUser }
                signIn={ signIn }
            />
            <Hero
                authUser={ authUser }
                signIn={ signIn }
            />

            <div
                className='page-content'
                id='learn-more'
            >
                <div id='brought-to-you-by-section'>
                    <h3>Brought To You By PushPress</h3>
                    <p>
                        Since 2013, <a href='https://www.pushpress.com'>PushPress Gym Management Software</a> has been
                        building a suite of software tools to help gyms make more money.
                    </p>
                </div>
                <div className='text-section img-right'>
                    <div className='img-container'>
                        <img src={ demo1 } />
                    </div>
                    <div className='text-container'>
                        <h2>
                            One Link To Drive Leads
                        </h2>
                        <p>
                            Take your fitlink wherever your audience is.
                            <br />Help them to discover your fitness studio or gym with ease.
                        </p>
                    </div>
                </div>

                <hr />

                <div className='text-section img-left'>
                    <div className='img-container'>
                        <img src={ demo2 } />
                    </div>
                    <div className='text-container'>
                        <h2>
                            Link to everywhere
                        </h2>
                        <p>
                            With one fitlink, you can send leads to your latest blog posts, workouts, videos, or studio
                            promotion.
                        </p>
                    </div>
                </div>

                <hr />

                <div className='text-section img-right'>
                    <div className='img-container'>
                        <img src={ demo3 } />
                        <span className='icon-credit'>
                            Icons made by <a
                            href='https://www.flaticon.com/authors/monkik'
                            title='monkik'
                        >monkik</a> from <a
                            href='https://www.flaticon.com/'
                            title='Flaticon'
                        > www.flaticon.com</a>
                        </span>
                    </div>
                    <div className='text-container'>
                        <h2>
                            Super Easy.
                        </h2>
                        <p>
                            Creating and managing your fitlink takes seconds. Use our simple drag-and-drop editor to
                            effortlessly manage your content.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
