import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { CompletedUser, LinkDoc } from '../../types';
import GraphTabSection from './GraphTabSection';

interface Props {
    authUser: CompletedUser;
}

export enum TimeRangeTab {
    Week,
    Month,
    Year,
    AllTime,
}

export enum IncreaseRangeTab {
    Daily,
    Weekly,
    Monthly,
    Annual,
}

export default function Stats(
    {
        authUser,
    }: Props,
): ReactElement {
    const [links, setLinks] = useState<LinkDoc[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        Promise
            .all(
                authUser.links.map(
                    link =>
                        firebase
                            .firestore()
                            .collection('links')
                            .doc(link.id)
                            .get()
                            .then(snapshot => snapshot.data() as LinkDoc),
                ),
            )
            .then((values: LinkDoc[]) => {
                setLoading(false);
                setLinks(values);
            });
    }, [authUser]);

    const getCtrDataMap = useCallback((timeRangeTab: TimeRangeTab, startTime: number, groupBy?: IncreaseRangeTab) => {
        if (!loading) {
            const timeNow = new Date().getTime();
            const groupPeriod = groupBy === undefined ? undefined : [1, 7, 31, 365][groupBy] * 24 * 60 * 60 * 1000;
            const filterFunction = (timestamp: number) =>
                timeRangeTab === TimeRangeTab.AllTime
                ? true
                : timestamp > timeNow - startTime;
            const earliestDatum = Math.min(
                ...links.map(
                    link => [...link.views, ...link.clicks].filter(filterFunction),
                ).flat(),
            );

            return links.map(
                (link): [number, number][] =>
                    [earliestDatum, ...link.views, ...link.clicks, timeNow]
                        .filter(filterFunction)
                        .sort()
                        .map(
                            (timestamp): [number, number] =>
                                [
                                    timestamp,
                                    (
                                        link.clicks.filter(click => click <= timestamp).length
                                        / link.views.filter(view => view <= timestamp).length
                                        * 100
                                    ) || 0,
                                ])
                        .map(
                            (datum): [number, number] =>
                                groupPeriod === undefined ? datum : [
                                    datum[0] - datum[0] % groupPeriod + earliestDatum % groupPeriod,
                                    datum[1],
                                ])
                        .reduce(
                            (acc, value) =>
                                acc.filter(
                                    timestamp => timestamp[0] === value[0],
                                ).length
                                ? acc.map(
                                    timestamp =>
                                        timestamp[0] === value[0]
                                        ? [
                                                timestamp[0],
                                                timestamp[1] + value[1],
                                                timestamp[2] + 1,
                                            ]
                                        : timestamp,
                                ) as [number, number, number][]
                                : [
                                    ...acc,
                                    [...value, 1],
                                ] as [number, number, number][], [] as [number, number, number][])
                        .map(
                            value =>
                                [
                                    value[0],
                                    parseFloat(
                                        (
                                            value[1] / value[2]
                                        ).toFixed(2),
                                    ),
                                ],
                        )
                        .map(
                            (value, index, array) =>
                                [
                                    value[0],
                                    groupBy ? parseFloat(
                                        (
                                            value[1] -
                                            (
                                                (array[index - 1] || [])[1]
                                                || 0
                                            )
                                        ).toFixed(2),
                                    ) : value[1],
                                ],
                        ),
            );
        }

        return [];
    }, [links, loading]);

    return (
        <div className='stats-page'>
            <h1>
                Your stats
            </h1>

            {
                loading ? (
                    <div className='loading-container'>
                        <Loader
                            type='TailSpin'
                            color='#00a1ea'
                            height={ 150 }
                            width={ 150 }
                        />
                    </div>
                ) : (
                    <>
                        <h2>
                            Link totals: views & clicks
                        </h2>
                        <GraphTabSection
                            timestamps={
                                [
                                    links.map(link => link.views).flat(),
                                    links.map(link => link.clicks).flat(),
                                ]
                            }
                            labels={ ['Total link views', 'Total link clicks'] }
                        />

                        {
                            ['views', 'clicks'].map(
                                (key) => (
                                    <>
                                        <h2>
                                            Link-by-link: { key }
                                        </h2>
                                        <GraphTabSection
                                            timestamps={
                                                links.map(link => link[key as 'views' | 'clicks'])
                                            }
                                            labels={
                                                authUser.links.map(
                                                    link => `'${ link.title }' ${ key }`,
                                                )
                                            }
                                        />
                                    </>
                                ),
                            )
                        }

                        <h2>
                            Link totals: click-through rate
                        </h2>
                        <GraphTabSection
                            timestamps={ [] }
                            forceDataMap={ getCtrDataMap }
                            labels={
                                [...authUser.links.map(
                                    link => `'${ link.title }' CTR`,
                                )]
                            }
                        />
                    </>
                )
            }
        </div>
    );
}
