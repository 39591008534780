import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import animateScrollTo from 'animated-scroll-to';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../types';
import heroImg from './img/demo/mobile-product-shot.png';
import heroImgMobile from './img/demo/desktop-product-shot-ksac.png';

interface Props {
    authUser: User | null;
    signIn: () => void;
}

export default function Hero(
    {
        authUser,
        signIn,
    }: Props,
): ReactElement {
    const scrollToContent = useCallback(() => {
        const target = document.getElementById('learn-more');

        if (target) {
            animateScrollTo(target, {
                cancelOnUserAction: true,
                speed: 300,
            });
        }
    }, []);

    const reactHistory = useHistory();
    const goToDashboard = useCallback(() => {
        reactHistory.push('/profile');
    }, [reactHistory]);

    const [heroSizeUpdater, setHeroSizeUpdater] = useState(0);
    const sizeSpecificImg = useMemo(() => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width <= 920) {
            return heroImgMobile;
        }

        return heroImg;
    }, [heroSizeUpdater]);

    useEffect(() => {
        window.onresize = () => {
            setHeroSizeUpdater(Math.random());
        };
    }, []);

    return (
        <div className='hero blue'>
            <div className='hero-content'>
                <div className='hero-body'>
                    <h1 className='hero-info'>The 100% Free Landing Page for Your Gym or Studio</h1>
                    <h2 className='hero-title'>
                        <strong>The Only Link You Need</strong>
                        <br />
                        For Your Fitness Business
                    </h2>
                    <h3 className='hero-subtitle'>
                        Drive and track leads from anywhere with
                        <br />
                        <strong>one free fitness link</strong>
                        .
                    </h3>
                    <button
                        className='btn-link white top hero-learn-more'
                        onClick={ scrollToContent }
                    >
                        Learn more&nbsp;
                        <FontAwesomeIcon icon={ faChevronCircleDown } />
                    </button>
                    {
                        authUser ? (
                            <Button
                                color='secondary'
                                variant='contained'
                                className='hero-cta'
                                size='large'
                                onClick={ goToDashboard }
                            >
                                Go to dashboard
                            </Button>
                        ) : (
                            <Button
                                color='secondary'
                                variant='contained'
                                className='hero-cta'
                                size='large'
                                onClick={ signIn }
                            >
                                Sign up with Google
                            </Button>
                        )
                    }
                </div>
                <div className='hero-img'>
                    <img src={ sizeSpecificImg } />
                </div>
            </div>
            <div className='hero-bottom'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 1440 320'
                >
                    <path
                        fill='#00a1ea'
                        fillOpacity='1'
                        d='M0,224L48,224C96,224,192,224,288,202.7C384,181,480,139,576,106.7C672,75,768,53,864,64C960,75,1056,117,1152,117.3C1248,117,1344,75,1392,53.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'
                    />
                </svg>
            </div>
        </div>
    );
};
