import { AppBar } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { ReactElement, useMemo, useState } from 'react';
import TimestampChart from '../../ui/TimestampChart';
import { IncreaseRangeTab, TimeRangeTab } from './Stats';

interface Props {
    timestamps: number[][];
    labels: string[];
    forceDataMap?: (timeRangeTab: TimeRangeTab, startTime: number, groupBy?: IncreaseRangeTab) => [number, number][][];
}

export default function GraphTabSection(
    {
        timestamps,
        labels,
        forceDataMap,
    }: Props,
): ReactElement {
    const [viewTab, setViewTab] = useState(0);
    const handleViewTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setViewTab(newValue);
    };

    const [timeRangeTab, setTimeRangeTab] = useState(TimeRangeTab.AllTime);
    const handleTimeRangeTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTimeRangeTab(newValue);
    };

    const [increaseRangeTab, setIncreaseRangeTab] = useState(IncreaseRangeTab.Weekly);
    const handleIncreaseRangeTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIncreaseRangeTab(newValue);
    };

    const startTime = [7, 31, 365, 0][timeRangeTab] * 24 * 60 * 60 * 1000;
    const filteredTimestamps = useMemo(() => {
        const timeNow = new Date().getTime();
        return timeRangeTab === TimeRangeTab.AllTime
               ? timestamps
               : timestamps.map(
                unfilteredTimestampList =>
                    unfilteredTimestampList.filter(
                        (timestamp: number) => timestamp > timeNow - startTime,
                    ),
            );
    }, [timeRangeTab, startTime, timestamps]);

    const forcedDataMap = useMemo(() => {
        return forceDataMap
               ? forceDataMap(timeRangeTab, startTime, viewTab === 1 ? increaseRangeTab : undefined)
               : undefined;
    }, [timeRangeTab, startTime, viewTab, increaseRangeTab, forceDataMap]);

    return (
        <>
            <AppBar
                position='static'
                color='primary'
            >
                <Tabs
                    value={ viewTab }
                    onChange={ handleViewTabChange }
                    variant='fullWidth'
                >
                    <Tab label='Total' />
                    <Tab label='Increase' />
                </Tabs>
            </AppBar>
            <div className='graph-tab-container'>
                <AppBar
                    position='static'
                    color='default'
                >
                    <Tabs
                        value={ timeRangeTab }
                        onChange={ handleTimeRangeTabChange }
                        variant='fullWidth'
                        indicatorColor='primary'
                        textColor='primary'
                    >
                        <Tab label='Week' />
                        <Tab label='Month' />
                        <Tab label='Year' />
                        <Tab label='All time' />
                    </Tabs>
                    <TimestampChart
                        timestamps={ forceDataMap ? [] : filteredTimestamps }
                        forceDataMap={ forcedDataMap }
                        labels={ labels }
                        groupBy={ viewTab === 1 ? increaseRangeTab : undefined }
                    />
                    {
                        viewTab === 1 && (
                            <Tabs
                                value={ increaseRangeTab }
                                onChange={ handleIncreaseRangeTabChange }
                                variant='fullWidth'
                                indicatorColor='secondary'
                                textColor='secondary'
                                TabIndicatorProps={ {
                                    style: {
                                        bottom: 'unset',
                                        top: 0,
                                    },
                                } }
                            >
                                <Tab label='Daily' />
                                <Tab label='Weekly' />
                                <Tab label='Monthly' />
                                <Tab label='Annual' />
                            </Tabs>
                        )
                    }
                </AppBar>
            </div>
        </>
    );
}
