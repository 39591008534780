import {
    createStyles,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    useScrollTrigger,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useCallback } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { isCompletedUser, User } from '../types';
import { handleFbLogout } from '../utils';
import icon from './img/icon.png';

import logo from './img/logo.png';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles(
            {
                root: {
                    flexGrow: 1,
                },
                menuButton: {
                    marginRight: theme.spacing(2),
                },
                logo: {
                    maxWidth: 200,
                    cursor: 'pointer',
                },
            },
        ),
);

interface ElevationProps {
    children: React.ReactElement;
}

function ElevationScroll(
    {
        children,
    }: ElevationProps,
) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

interface Props {
    authUser: User | null;
    signIn?: () => void;
    page?: string;
}

export default function Navbar(
    {
        authUser,
        signIn,
        page,
    }: Props,
): ReactElement {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const reactHistory = useHistory();
    const handleSignOut = useCallback(() => {
        handleFbLogout(() => {
            enqueueSnackbar('You\'ve been signed out.', {
                variant: 'info',
            });

            reactHistory.push('/');
        });

        handleClose();
    }, [enqueueSnackbar, handleClose, reactHistory]);

    const handleDashboardClick = useCallback(() => {
        reactHistory.push('/profile');
    }, [reactHistory]);

    const username = (authUser && isCompletedUser(authUser)) && authUser.username;

    return (
        <div className={ classes.root }>
            <ElevationScroll>
                <AppBar position='static'>
                    <Toolbar>
                        <Link to='/'>
                            <img
                                src={ logo }
                                alt='Logo'
                                className='nav-img logo'
                            />
                            <img
                                src={ icon }
                                alt='Logo'
                                className='nav-img icon'
                            />
                        </Link>
                        <Typography
                            variant='h6'
                            className='nav-title'
                        >
                            {
                                page || 'Home'
                            }
                        </Typography>
                        <div className='align-right'>
                            { authUser || !signIn ? (
                                <>
                                    {
                                        authUser && username && (
                                            <span className='nav-user-url'>
                                                My URL:&nbsp;
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href={ `/${ username }` }
                                                >
                                                    fitlink.io/{ username }
                                                </a>
                                            </span>
                                        )
                                    }

                                    <IconButton
                                        aria-label='account of current user'
                                        aria-controls='menu-appbar'
                                        aria-haspopup='true'
                                        onClick={ handleMenu }
                                        color='inherit'
                                    >
                                        {
                                            authUser?.iconUrl ? (
                                                <Avatar src={ authUser?.iconUrl } />
                                            ) : (
                                                <AccountCircle />
                                            )
                                        }
                                    </IconButton>
                                    <Menu
                                        id='menu-appbar'
                                        anchorEl={ anchorEl }
                                        anchorOrigin={ {
                                            vertical: 'top',
                                            horizontal: 'right',
                                        } }
                                        keepMounted
                                        transformOrigin={ {
                                            vertical: 'top',
                                            horizontal: 'right',
                                        } }
                                        open={ !!anchorEl }
                                        onClose={ handleClose }
                                    >
                                        <MenuItem onClick={ handleDashboardClick }>
                                            Dashboard
                                        </MenuItem>
                                        <MenuItem onClick={ handleSignOut }>
                                            Sign out
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                  <>
                                      <button
                                          className='btn-link white'
                                          onClick={ signIn }
                                      >
                                          Log in
                                      </button>
                                      <Button
                                          color='secondary'
                                          variant='contained'
                                          onClick={ signIn }
                                      >
                                          Sign up with Google
                                      </Button>
                                  </>
                              ) }
                        </div>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </div>
    );
}
