import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import arrayMove from 'array-move';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { v4 as uuid } from 'uuid';
import { CompletedUser, Link, User } from '../../types';
import LinkItem from '../../ui/LinkItem';
import { createLinkDoc, generateShortenedURL, updateUser } from '../../utils';
import { useSnackbar } from 'notistack';

interface Props {
    authUser: CompletedUser;
}

interface LinkData extends Link {
    links: Link[];
    validLinks: Link[];
    setLinks: (newLinks: Link[]) => void;
    setLocalLinks: (newLocalLinks: Link[]) => void;
    authUser: User;
}

interface SortableItemProps {
    value: LinkData;
}

const SortableItem = SortableElement(
    ({ value }: SortableItemProps) => {
        const setLink = (newLink: Link) => {
            value.setLinks(
                value.links.map(link => link.id === newLink.id ? {
                    index: link.index,
                    id: link.id,
                    isPriority: newLink.isPriority,
                    disabled: !!newLink.disabled,
                    title: newLink.title.trim() || value.validLinks.filter(e => e.id === link.id)[0].title,
                    description: newLink.description.trim(),
                    url: newLink.url.trim()
                         ? newLink.url.trim().startsWith('http')
                           ? newLink.url.trim()
                           : `https://${ newLink.url.trim() }`
                         : value.validLinks.filter(e => e.id === link.id)[0].url,
                    shortenedAs: link.shortenedAs,
                } : {
                    index: link.index,
                    id: link.id,
                    isPriority: newLink.isPriority ? false : link.isPriority,
                    disabled: link.disabled,
                    title: link.title,
                    description: link.description,
                    url: link.url,
                    shortenedAs: link.shortenedAs,
                }),
            );

            value.setLocalLinks(
                value.links.map(link => link.id === newLink.id ? newLink : {
                    ...link,
                    isPriority: newLink.isPriority ? false : link.isPriority,
                }),
            );
        };

        return (
            <div>
                <LinkItem
                    link={ value }
                    setLink={ setLink }
                    deleteLink={
                        () => {
                            const newLinks = value
                                .links
                                .filter(comparisonLink => comparisonLink.id !== value.id);

                            value.setLinks(newLinks);
                            value.setLocalLinks(newLinks);
                        }
                    }
                    editable
                    key={ value.id }
                    authUser={ value.authUser }
                />
            </div>
        );
    },
);

interface LinkListData {
    links: Link[];
    validLinks: Link[]
    setLinks: (newLinks: Link[]) => void;
    setLocalLinks: (newLocalLinks: Link[]) => void;
    authUser: User;
}

interface SortableProps {
    items: LinkListData,
}

const SortableList = SortableContainer(({ items }: SortableProps) => {
    return (
        <div className='link-container'>
            { items.links.map((value: Link, index: number) => (
                <SortableItem
                    key={ `item-${ value.id }` }
                    index={ index }
                    value={
                        {
                            ...value,
                            links: items.links,
                            setLinks: items.setLinks,
                            setLocalLinks: items.setLocalLinks,
                            validLinks: items.validLinks,
                            authUser: items.authUser,
                        }
                    }
                />
            )) }
        </div>
    );
});

export default function Links(
    {
        authUser,
    }: Props,
): ReactElement {
    const [links, setLinks] = useState(authUser.links);

    // Create a local clone in order to allow a potentially invalid state locally, but maintain the last valid
    // state in the DB.
    const [localLinks, setLocalLinks] = useState(authUser.links);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const addLink = useCallback(() => {
        const id = uuid();
        enqueueSnackbar('Creating link...', {
            variant: 'info',
            preventDuplicate: true,
            key: 'new-link',
            persist: true,
        });

        generateShortenedURL()
            .then(shortenedAs => {
                const newLink = {
                    isPriority: false,
                    disabled: false,
                    index: links.length,
                    title: 'New link',
                    description: '',
                    url: `https://fitlink.io/${ authUser.username }`,
                    id,
                    shortenedAs,
                };

                createLinkDoc(id, authUser)
                    .then(() => {
                        setLinks(
                            [
                                ...links,
                                newLink,
                            ],
                        );

                        setLocalLinks(
                            [
                                ...links,
                                {
                                    isPriority: false,
                                    disabled: false,
                                    index: links.length,
                                    title: '',
                                    description: '',
                                    url: '',
                                    id,
                                    shortenedAs,
                                },
                            ],
                        );

                        closeSnackbar('new-link');
                    });
            });
    }, [authUser, links]);

    useEffect(() => {
        updateUser(
            authUser,
            {
                links: links
                    .sort((a, b) => a.index - b.index)
                    .map((e, index) => (
                        {
                            ...e,
                            index,
                        }
                    )),
            },
        );
    }, [links]);

    return (
        <div className='edit-links'>
            <SortableList
                items={ {
                    links: localLinks,
                    setLinks,
                    setLocalLinks,
                    validLinks: links,
                    authUser,
                } }
                onSortEnd={
                    ({ oldIndex, newIndex }) => {
                        setLinks(
                            arrayMove(links, oldIndex, newIndex)
                                .map((item, index) =>
                                    ({ ...item, index }),
                                ),
                        );
                        setLocalLinks(
                            arrayMove(localLinks, oldIndex, newIndex)
                                .map((item, index) =>
                                    ({ ...item, index }),
                                ),
                        );
                    }
                }
                lockAxis='y'
                pressThreshold={ 15 }
                distance={ 10 }
                lockToContainerEdges={ true }
                lockOffset={ ['-120px', '-80px'] }
            />
            {
                !links.length && (
                    <div className='no-links-warning'>
                        <h1>
                            You don't have any links yet!
                        </h1>

                        <p>
                            To get started, create a link by pressing&nbsp;
                            <strong>
                                <FontAwesomeIcon icon={ faPlus } />
                                &nbsp;Add
                            </strong>
                            &nbsp;and editing the title, URL and description.
                        </p>
                    </div>
                )
            }
            <Button
                onClick={ addLink }
                variant='contained'
                className='add-link'
                color='secondary'
            >
                <FontAwesomeIcon icon={ faPlus } />
                &nbsp;Add
            </Button>
        </div>
    );
}
