import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons/faInstagramSquare';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons/faYoutubeSquare';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons/faEnvelopeSquare';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkSquareAlt';

import { faPhoneSquare } from '@fortawesome/free-solid-svg-icons/faPhoneSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from '@material-ui/core';

import copy from 'copy-text-to-clipboard';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { isCompanyUser, isCompletedUser, UserType } from './types';
import LinkItem from './ui/LinkItem';
import LoadingPage from './ui/LoadingPage';
import { appendQueryParameter, handleLinkClickUpdate, switchLightDark, useShortenedLink, useUsername } from './utils';

interface Props {
    forceUsername?: string;
}

export default function ViewLinks(
    {
        forceUsername,
    }: Props,
): ReactElement {
    const { username } = useParams();
    const targetUser = forceUsername || username;

    const [link, linkDoc, linkLoading] = useShortenedLink(forceUsername ? '-' : username);
    const [user, loading] = useUsername(targetUser);
    const { enqueueSnackbar } = useSnackbar();

    const [loadTime] = React.useState(new Date().getTime());

    useEffect(() => {
        if (user && isCompanyUser(user) && !forceUsername) {
            if (user.companyPxId) {
                ReactPixel.init(user.companyPxId);
                ReactPixel.grantConsent();

                ReactPixel.pageView();
            }

            if (user.companyGaId) {
                ReactGA.initialize(user.companyGaId);
                ReactGA.pageview(window.location.pathname);
            }
        }
    }, [user, forceUsername]);

    useEffect(() => {
        if (user) {
            document.title = `Fitlink - ${ user.name } (@${ targetUser })`;
        }
    }, [user]);

    useEffect(() => {
        if (link) {
            handleLinkClickUpdate(linkDoc, link, () => {
                window.location.replace(appendQueryParameter(link.url, 'utm_source', 'fitlinkio'));
            });
        }
    }, [link, linkDoc]);

    if (loading || linkLoading) {
        return <LoadingPage />;
    }

    if (link) {
        return (
            <LoadingPage />
        );
    }

    if (!user || !isCompletedUser(user)) {
        return (
            <div className='error-page'>
                <div className='content'>
                    <h1>Uh oh! We couldn't find the user&nbsp;
                        <strong>
                            '{ targetUser }'
                        </strong>
                        .
                    </h1>
                    <p>
                        This might be because:
                        <ul>
                            <li>
                                A user by this username doesn't exist
                            </li>
                            <li>
                                This user has not filled in their profile
                            </li>
                            <li>
                                This user's account has been deactivated or suspended
                            </li>
                            <li>
                                This user doesn't have any links yet
                            </li>
                        </ul>
                    </p>
                    <p
                        style={ {
                            marginTop: 35,
                        } }
                    >
                        If you believe that this is a mistake, please&nbsp;
                        <a
                            href={ `mailto:contact@fitlink.io?subject=User%20not%20found%3A%20${ targetUser }&body=Hi%2C%0D%0A%0D%0AI'm%20getting%20in%20touch%20because%20on%20a%20recent%20visit%20to%20your%20site%2C%20a%20message%20popped%20up%20saying%20that%20%22We%20couldn't%20find%20the%20user%20'${ targetUser }'%22.%20However%2C%20I%20think%20this%20is%20a%20mistake%2Fissue%2Fbug%20%3Cchoose%20one%3E%20because...` }
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            get in touch
                        </a>
                        .
                    </p>
                </div>
            </div>
        );
    }

    const userConnections = user.userType === UserType.Company ? (
        [
            {
                icon: faExternalLinkSquareAlt,
                url: user.companyWebsite,
                alt: `Visit ${ user.companyWebsite }`,
                show: !!user.companyWebsite,
            },
            {
                icon: faEnvelopeSquare,
                url: `mailto:${ user.companyEmail }`,
                alt: 'Email us',
                show: !!user.companyEmail,
            },
            {
                icon: faPhoneSquare,
                url: `tel:${ user.companyPhone }`,
                alt: 'Give us a call',
                show: !!user.companyPhone,
            },
            {
                icon: faFacebookSquare,
                url: `https://${ user.companyFb }`,
                alt: 'Our Facebook',
                show: !!user.companyFb,
            },
            {
                icon: faInstagramSquare,
                url: `https://${ user.companyIg }`,
                alt: 'Our Instagram',
                show: !!user.companyIg,
            },
            {
                icon: faYoutubeSquare,
                url: `https://${ user.companyYt }`,
                alt: 'Our YouTube channel',
                show: !!user.companyYt,
            },
            {
                icon: faTwitterSquare,
                url: `https://${ user.companyTw }`,
                alt: 'Our Twitter',
                show: !!user.companyTw,
            },
            {
                icon: faTiktok,
                url: `https://${ user.companyTt }`,
                alt: 'Our TikTok',
                show: !!user.companyTt,
            },
        ]
    ) : [];

    return (
        <div
            className='link-page'
            id='link-page'
            style={ {
                background: user.theme.background,
            } }
        >
            <div
                className='user-profile'
                style={ {
                    fontFamily: user.theme.font,
                } }
            >
                {
                    user.iconUrl && (
                        <Avatar
                            src={ user.iconUrl }
                            className='user-icon-large avatar-no-cut'
                        />
                    )
                }
                <h1
                    style={ {
                        color: switchLightDark(user.theme.background, '#ffffff', '#000000'),
                    } }
                >
                    {
                        user.userType === UserType.Company
                        ? user.companyName
                        : user.name
                    }
                </h1>
                {
                    user.bio.length ? (
                        <p
                            className='user-bio'
                            style={ {
                                color: switchLightDark(user.theme.background, '#d6d6d6', '#7a7a7a'),
                            } }
                        >
                            { user.bio }
                        </p>
                    ) : (
                        <p
                            className='username'
                            data-tip='Copy user URL to clipboard'
                            onClick={ () => {
                                const success = copy(`https://fitlink.io/${ targetUser }`);

                                if (success) {
                                    enqueueSnackbar('URL copied to clipboard!', {
                                        variant: 'info',
                                        autoHideDuration: 1000,
                                        preventDuplicate: true,
                                    });
                                }
                            } }
                            style={ {
                                color: switchLightDark(user.theme.background, '#d6d6d6', '#7a7a7a'),
                            } }
                        >
                            @{ user.username }
                        </p>
                    )
                }
                <ReactTooltip
                    effect='solid'
                    place='bottom'
                />

                {
                    user.userType === UserType.Company && (
                        <div className='connections-container'>
                            {
                                userConnections
                                    .filter(connection => connection.show)
                                    .map(connection => (
                                            <a
                                                href={ connection.url }
                                                key={ connection.url }
                                                rel='noreferrer noopener'
                                                target='_blank'
                                            >
                                                <FontAwesomeIcon
                                                    icon={ connection.icon }
                                                    title={ connection.alt }
                                                    style={ {
                                                        color: switchLightDark(
                                                            user.theme.background,
                                                            '#ffffff',
                                                            '#000000',
                                                        ),
                                                    } }
                                                />
                                            </a>
                                        ),
                                    )
                            }
                        </div>
                    )
                }
            </div>
            <div className='link-container'>
                {
                    user.links
                        .filter(link => !link.disabled)
                        .map(link => link.title.trim() && (
                            <LinkItem
                                link={ link }
                                editable={ false }
                                theme={ user.theme }
                                key={ link.id }
                                registerAnalytics={ !forceUsername }
                                forceAnalyticsTime={ loadTime }
                            />
                        ))
                }
            </div>
            <div
                className='branding-container'
                style={ {
                    color: switchLightDark(
                        user.theme.background,
                        '#ffffff',
                        '#000000',
                    ),
                } }
            >
                <div className='branding-link'>
                    <a
                        href='https://fitlink.io'
                        style={ {
                            color: switchLightDark(
                                user.theme.background,
                                '#ffffff',
                                '#000000',
                            ),
                        } }
                    >
                        fitlink.io - Free fitness links
                    </a>
                </div>
                <div className='branding-link'>
                    <a
                        href='https://pushpress.com'
                        style={ {
                            color: switchLightDark(
                                user.theme.background,
                                '#ffffff',
                                '#000000',
                            ),
                        } }
                    >
                        another gym management tool from PushPress
                    </a>
                </div>
            </div>
        </div>
    );
}
