import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { ReactElement, useState } from 'react';
import ChromePicker from 'react-color/lib/components/chrome/Chrome';

interface Props {
    handleClose: (background?: string) => void;
    initialValue?: string;
}

export default function BackgroundEditModal(
    {
        handleClose,
        initialValue,
    }: Props,
): ReactElement {
    const [selectedBackground, setSelectedBackground] = useState(initialValue || '#ffffff');

    return (
        <Dialog
            onClose={ () => handleClose() }
            open={ true }
        >
            <DialogTitle id='alert-dialog-title'>Choose custom background</DialogTitle>
            <DialogContent
                className='background-edit-content'
            >
                <ChromePicker
                    color={ selectedBackground }
                    onChange={ (color) => setSelectedBackground(color.hex) }
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={ () => handleClose() }
                    color='primary'
                >
                    Cancel
                </Button>
                <Button
                    onClick={ () => handleClose(selectedBackground) }
                    color='primary'
                    autoFocus
                    disabled={ !selectedBackground }
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}
