import { MuiThemeProvider } from '@material-ui/core/styles';

import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import 'firebase/analytics';

import * as firebase from 'firebase/app';

import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import Navigation from './Navigation';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import LogRocket from 'logrocket';

import './styles/algolia.scss';
import './styles/inputs.scss';

import './styles/layout.scss';

Sentry.init({
    dsn: 'https://023ee210b9934366a0695f51462e54c7@o442943.ingest.sentry.io/5415725',
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

LogRocket.init('hdvtgl/fitlinkio');

const firebaseConfig = {
    apiKey: 'AIzaSyDJQioeyY2EuPyo0DgfKMmB4QbTSFrxH9s',
    authDomain: 'pushpress-linktree.firebaseapp.com',
    databaseURL: 'https://pushpress-linktree.firebaseio.com',
    projectId: 'pushpress-linktree',
    storageBucket: 'pushpress-linktree.appspot.com',
    messagingSenderId: '161314357980',
    appId: '1:161314357980:web:c7536aaf7b7020ab1c6a19',
    measurementId: 'G-P7ZMKSJWMV',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const muiTheme = createMuiTheme(
    {
        palette: {
            primary: {
                main: '#00a1ea',
                contrastText: '#fff',
            },
            secondary: {
                main: '#fe3a60',
                contrastText: '#fff',
            },
        },
    }
);

const history = createBrowserHistory();

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
!function () {
    // @ts-ignore
    var analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize) {
        if (analytics.invoked) {
            window.console && console.error && console.error(
                'Segment snippet included twice.');
        } else {
            analytics.invoked = !0;
            analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'];
            analytics.factory = function (e: any) {
                return function () {
                    var t = Array.prototype.slice.call(arguments);
                    t.unshift(e);
                    analytics.push(t);
                    return analytics;
                };
            };
            for (var e = 0; e < analytics.methods.length; e++) {
                var key = analytics.methods[e];
                analytics[key] = analytics.factory(key);
            }
            analytics.load = function (key: any, e: any) {
                var t = document.createElement('script');
                t.type = 'text/javascript';
                t.async = !0;
                t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                document.body.insertBefore(t, document.body.firstChild);
                analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = '4.13.1';
            analytics.load(
                ['/', '/profile/', '', '/profile'].includes(window.location.pathname)
                ? 'AKccHFMYVWJGxSJLzmd3g1Eo8U1d82Gd'
                : 'KSyCwTodhKQ2EJW4Rrt5DEVz5gw41SS5'
            );
        }
    }
}();

ReactDOM.render(
    <React.StrictMode>
        <SnackbarProvider maxSnack={ 2 }>
            <MuiThemeProvider theme={ muiTheme }>
                <Router history={ history }>
                    <Navigation />
                </Router>
            </MuiThemeProvider>
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
