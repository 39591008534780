import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { ProviderContext } from 'notistack';
import { useEffect, useState } from 'react';
import {
    BasicLinkDoc,
    CompletedUser,
    ContextualLink,
    DirtyFirebaseUpdateObj,
    FirebaseUpdateObj,
    IncompleteUser,
    isCompanyUser,
    isCompletedUser,
    isUnfurled,
    Link,
    LinkDoc,
    User,
    UserType
} from './types';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

export function validateUrl(url: string): boolean {
    try {
        const parsed = new URL(url.trim().startsWith('http') ? url.trim() : `https://${url.trim()}`);
        if (parsed.href && parsed.host.includes('.')) {
            return true;
        }
    } catch {
        return false;
    }

    return false;
}

export function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function filterUsername(username: string, startPattern?: string): string {
    return username
        .replace(
            new RegExp(
                `^(${
                    escapeRegExp(startPattern || '/')
                }?)*`,
                'gi',
            ),
            '',
        )
        .replace(
            new RegExp(
                `^(http(s?)://${ startPattern
                                 ? escapeRegExp(new URL(`https://${ startPattern }`).hostname)
                                 : ''
                }/?)*`,
                'gi',
            ),
            '',
        )
        .replace(/[^a-zA-Z0-9_.-]/g, '');
}

export function handleLinkClickUpdate(
    linkInfo: Partial<LinkDoc> | undefined | null,
    link: Link,
    callback?: () => void
): void {
    firebase
        .firestore()
        .collection('links')
        .doc(link.id)
        .update({
            clicks: (linkInfo?.clicks || []).concat(new Date().getTime()),
        })
        .finally(() => {
            if (linkInfo?.gaId) {
                ReactGA.initialize(linkInfo.gaId);
                ReactGA.event(
                    {
                        category: 'Link click',
                        action: 'Clicked on a link',
                        label: link.title,
                    },
                );
            }

            if (callback) {
                callback();
            }
        });
}

export function useShortenedLink(symbol: string): [Link | null, Partial<LinkDoc> | null, boolean] {
    const [link, setLink] = useState<Link | null>(null);
    const [loading, setLoading] = useState(true);
    const [linkDoc, setLinkDoc] = useState<Partial<LinkDoc> | null>(null);

    useEffect(() => {
        setLoading(true);

        getLinkWhere('shortenedAs', symbol)
            .then(async goodLink => {
                if (!goodLink) {
                    setLink(null);
                    setLinkDoc(null);
                    setLoading(false);
                    return;
                }

                const linkDocHere = await firebase
                    .firestore()
                    .collection('links')
                    .doc(goodLink.id)
                    .get();

                if (!linkDocHere.exists) {
                    await createLinkDoc(goodLink.id, goodLink.parent, doc => {
                        setLinkDoc(doc);
                    });
                } else {
                    setLinkDoc(linkDocHere.data() as LinkDoc);
                }

                setLink(goodLink);
                setLoading(false);
            });
    }, [symbol]);

    return [link, linkDoc, loading];
}

export function getOrCreateLinkDoc(linkId: string, user: User | undefined, callback: (snapshot: BasicLinkDoc) => void) {
    firebase
        .firestore()
        .collection('links')
        .doc(linkId)
        .get()
        .then((snapshot) => {
            if (snapshot.exists) {
                callback && callback(snapshot.data() as LinkDoc);
            } else {
                if (user && isCompletedUser(user)) {
                    createLinkDoc(linkId, user, callback);
                }
            }
        });
}

export function useUsername(username: string): [User | null, boolean] {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        firebase
            .firestore()
            .collection('users')
            .where('username', '==', username)
            .onSnapshot(snapshot => {
                setLoading(false);

                if (snapshot.empty) {
                    setUser(null);
                } else {
                    setUser(snapshot.docs[0].data() as User);
                }
            });
    }, [username]);

    return [user, loading];
}

export function useFirebaseAuthUser(): [User | null, boolean, boolean] {
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [showSignUpFlow, setShowSignUpFlow] = useState(false);
    const [userDataIsLoading, setUserDataIsLoading] = useState(true);
    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                firebase
                    .firestore()
                    .collection('users')
                    .doc(user.uid)
                    .onSnapshot(snapshot => {
                        if (snapshot.exists) {
                            const documentData = snapshot.data() as User;
                            setAuthUser(documentData);
                            setUserDataIsLoading(false);

                            if (documentData.completedSignUp) {
                                setShowSignUpFlow(false);
                            } else {
                                setShowSignUpFlow(true);
                            }
                        } else {
                            const email = user.email;
                            const name = user.displayName;

                            if (email && name) {
                                const newUser: User = {
                                    uid: user.uid,
                                    name,
                                    email,
                                    completedSignUp: false,
                                    iconUrl: user.photoURL,
                                };

                                firebase
                                    .firestore()
                                    .collection('users')
                                    .doc(user.uid)
                                    .set(newUser, {
                                        merge: true,
                                    })
                                    .then(() => {
                                        setAuthUser(newUser);
                                        setUserDataIsLoading(false);
                                        setShowSignUpFlow(true);
                                    });
                            }
                        }
                    });
            } else {
                setAuthUser(null);
                setUserDataIsLoading(false);
            }
        });
    }, []);

    return [authUser, userDataIsLoading, showSignUpFlow];
}

export function handleFbLogout(callback?: () => void): void {
    firebase
        .auth()
        .signOut()
        .then(() => {
            callback && callback();
        });
}

export function handleFbAuth(notistack: ProviderContext, successCallback?: (user: firebase.User) => void) {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();

    let success = false;
    firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
            if (result.user) {
                if (result.additionalUserInfo?.isNewUser) {
                    // @ts-ignore
                    window.analytics?.track('Account Registered', {
                        user: {
                            email: result.user.email,
                            name: result.user.displayName,
                            id: result.user.uid,
                        },
                    });
                }

                notistack.enqueueSnackbar(`Successfully signed in as ${result.user.email}`, {
                    variant: 'success',
                });

                success = true;
                successCallback && successCallback(result.user);
            }
        })
        .catch(() => {
            if (!success) {
                notistack.enqueueSnackbar('Uh oh! Something went wrong while signing you in. Try again.', {
                    variant: 'error',
                });
            }
        });
}

export function cleanUsername(username: string): string {
    return username
        .replace(/ /g, '-')
        .replace(/[^0-9a-zA-Z._~-]/g, '')
        .slice(0, 20)
        .toLowerCase();
}

export async function usernameIsTaken(username: string): Promise<Boolean> {
    const takenByUser = await firebase
        .firestore()
        .collection('users')
        .where('username', '==', username)
        .get();

    return !takenByUser.empty || (await getLinkWhere('shortenedAs', username) !== null);
}

export async function generateShortenedURL(): Promise<string> {
    const rawUsers = await firebase
        .firestore()
        .collection('users')
        .get();
    const users = rawUsers.docs.map(doc => doc.data() as User);

    const soup = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    while (true) {
        const url = Array(7).fill(0).map(() => soup[Math.floor(Math.random() * soup.length)]).join('');

        if (url.toLowerCase() === 'profile') {
            continue;
        }

        let isGood = true;
        for (const user of users) {
            if (isCompletedUser(user)) {
                if (user.links.some(link => link.shortenedAs === url) || user.username === url) {
                    isGood = false;
                    break;
                }
            }
        }

        if (isGood) {
            return url;
        }
    }
}

export async function cleanFbUpdateObj(
    data: DirtyFirebaseUpdateObj
): Promise<FirebaseUpdateObj> {
    const filteredNewData = Object.assign({}, data) as FirebaseUpdateObj;
    Object
        .keys(filteredNewData)
        .forEach(key =>
            (
                filteredNewData[key] === undefined
                || filteredNewData[key] === null
                || (
                    key === 'links'
                    && !(
                        (filteredNewData[key] || []) as unknown as Link[]
                    ).every(e =>
                        (
                            Object.keys(e) as (keyof Link)[]
                        )
                            .every((linkKey: keyof Link) =>
                                ['isPriority', 'index', 'title', 'description', 'id', 'url', 'disabled', 'shortenedAs']
                                    .includes(linkKey)
                                && ['boolean', 'string', 'number'].includes(typeof e[linkKey])
                            )
                    )
                )
            )
            && delete filteredNewData[key]
        );

    if (filteredNewData.links) {
        for (const link of filteredNewData.links as unknown as Link[]) {
            let index = (filteredNewData.links as unknown as Link[]).indexOf(link);
            if (!link.hasOwnProperty('disabled')) {
                (filteredNewData.links as unknown as Link[])[index] = {
                    ...link,
                    disabled: false,
                };
            }

            if (!link.hasOwnProperty('shortenedAs')) {
                (filteredNewData.links as unknown as Link[])[index] = {
                    ...link,
                    shortenedAs: await generateShortenedURL(),
                };
            }
        }
    }

    return filteredNewData;
}

export async function getLinkWhere<K extends Link, T extends keyof Link>(
    property: T,
    value: K[T]
): Promise<ContextualLink | null> {
    const snapshot = await firebase
        .firestore()
        .collection('users')
        .get();

    for (const doc of snapshot.docs) {
        const user = doc.data() as User;
        if (isCompletedUser(user)) {
            const goodLink = user.links.filter(link => link[property] === value);

            if (goodLink.length) {
                return {
                    ...goodLink[0],
                    parent: user,
                };
            }
        }
    }

    return null;
}

export function unfurlLinkDoc(linkId: string): void {
    const ref = firebase
        .firestore()
        .collection('links')
        .doc(linkId);

    ref
        .get()
        .then(snapshot => {
            if (!snapshot.exists) {
                return;
            }

            const doc = snapshot.data() as LinkDoc;
            console.log(doc);

            if (!isUnfurled(doc)) {
                getLinkWhere('id', linkId)
                    .then(doc => {
                        console.log(doc);

                        if (doc) {
                            fetch(doc.url)
                                .then(e => {
                                    console.log(e.status, e.statusText);
                                    return e;
                                })
                                .then(data => data.text())
                                .then(async text => {
                                    // const metadata = await metascraper({ html: text, url: doc.url })
                                    // console.log(metadata);
                                })
                                .catch(e => {
                                    // console.log(e);
                                });
                        }
                    });
            }
        })
        .catch(() => {
        });
}

export async function updateUser<T extends UserType>(
    authUser: User<T> | IncompleteUser,
    newData: Partial<User<T> | IncompleteUser>,
    callback?: (cleanedData: User<T>) => void,
): Promise<void> {
    const cleanData = await cleanFbUpdateObj(newData as DirtyFirebaseUpdateObj);

    return firebase
        .firestore()
        .collection('users')
        .doc(authUser.uid)
        .update(cleanData)
        .then(() => {
            callback && callback(cleanData as unknown as User<T>);
        })
        .catch(() => {
        });
}

export function createLinkDoc(
    id: string,
    authUser: CompletedUser,
    callback?: (newLink: BasicLinkDoc) => void,
): Promise<any> {
    const linkObj = {
        clicks: [],
        views: [],
    } as Partial<LinkDoc> & BasicLinkDoc;

    if (isCompanyUser(authUser)) {
        linkObj.gaId = authUser.companyGaId;
        linkObj.pixelCode = authUser.companyPxId;
    }

    return firebase
        .firestore()
        .collection('links')
        .doc(id)
        .set(linkObj)
        .then(() => {
            callback && callback(linkObj);
        })
        .catch(() => {
        });
}

export function switchLightDark(color: string, light: string, dark: string): string {
    const sections = color.startsWith('#')
                     ? color
                         .slice(1)
                         .split(/(.{2})/g)
                         .filter(e => e)
                         .map(e => parseInt(e, 16))
                     : color
                         .split('rgba(')[1]
                         .split(')')[0]
                         .split(',')
                         .map(e => parseInt(e));

    if ((sections[0] * 0.299 + sections[1] * 0.587 + sections[2] * 0.114) > 186) {
        return dark;
    }

    return light;
}

export async function asyncSome<T>(
    array: T[],
    callback: (item: T, index: number, array: T[]) => Promise<boolean>,
): Promise<boolean> {
    for (let index = 0; index < array.length; index++) {
        const value = await callback(array[index], index, array);

        if (value) {
            return true;
        }
    }

    return false;
}

export function updateUserLinks(
    authUser: CompletedUser,
    newData: Partial<LinkDoc>
): void {
    authUser.links.forEach((link) =>
        cleanFbUpdateObj(newData)
            .then((cleanLink) => {
                firebase
                    .firestore()
                    .collection('links')
                    .doc(link.id)
                    .update(cleanLink)
                    .catch(() => {
                    });
            })
    );
}

// https://stackoverflow.com/a/62916568
export function appendQueryParameter(url: string, name: string, value: string): string {
    if (url.length === 0) {
        return url;
    }

    let rawURL = url;
    if (rawURL.charAt(rawURL.length - 1) === '?') {
        rawURL = rawURL.slice(0, rawURL.length - 1);
    }

    const parsedURL = new URL(rawURL);
    let parameters = parsedURL.search;

    parameters += (parameters.length === 0) ? '?' : '&';
    parameters = `${parameters}${name}=${value}`;

    return `${parsedURL.origin}${parsedURL.pathname}${parameters}`;
}

export function registerSegmentPage() {
    if (['/', '/profile/', '', '/profile'].includes(window.location.pathname)) {
        // @ts-ignore
        window.analytics?.page();
    } else {
        // @ts-ignore
        window.analytics?.page(`${window.location.pathname.replace(/^\//g, '')} Link Page`, {}, {
            integrations: {
                'All': false,
                'Google Analytics': true,
            }
        });
    }
}
